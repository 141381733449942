<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
            <v-card>
              <v-card-title> <v-icon size="28" class="pageicon">library_add</v-icon> Add Course</v-card-title>
                  <v-row>
                      <v-col cols="12" sm="12" class="text-right" >
                          <v-btn color="success" class="ma-5 white--text" @click="addNewCourse" >
                          Add Course
                          <v-icon right dark >save</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
                  <v-row class="row-card">
                      <v-col cols="12" sm="4" >
                          <v-text-field
                              label="Name"
                              v-model="course.name"
                              required
                              @output="$v.course.name.$touch()"
                              @blur="$v.course.name.$touch()"
                              :error-messages="!$v.course.name.required ? ['Name is required'] : ''"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" >
                        <v-select :items="types" v-model="course.type" 
                            label="Type" outlined attach
                            :error-messages="!$v.course.type.required ? ['Type is required'] : ''"
                        >
                        </v-select>
                      </v-col>
                  </v-row>
            </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import 'vuejs-noty/dist/vuejs-noty.css'
import Noty from 'noty';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  validations: {
    course:{
        name: { required },
        type: {required }
    }
  },
  name: "Add-Course",
  data() {
    return {
        course: {
            name: null,
            type: null
        },
        types: ['MQA', 'OTHER']
    };
  },
  methods: {
        addNewCourse(){

            if(this.$v.$invalid  ){
                this.$v.$touch();
            }
            else{

                this.listLoading = true
                let loader = this.$loading.show({
                    // Optional parameters
                    //container: this.$refs.formContainer,
                    //isFullPage: false
                    });

                var vm = this;
                const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

                this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/course/post?userName='+localStorage.getItem('username'),this.course,config)
                .then(response => {

                    
                    loader.hide();

                    //notification of successfully saved
                    var notification = new Noty({
                        killer: false,
                        timeout: 5000,
                        type: 'success',
                        text: 'Course ' +vm.course.name+ ' is created.',
                        theme: 'metroui',
                        layout: 'topRight'
                    })
                    notification.show();
                    vm.$router.push({
                        path: "/course-list"
                    });
                    
                })
                .catch(function (error) {
                    loader.hide();
                    vm.listLoading = false;
                    console.error(error);
                });
            }
        }
   }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
</style>
