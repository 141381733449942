<template>
    <div ref="formContainer">
    <v-dialog v-model="show" scrollable max-width="600px" content-class="dialogbg">
        
            <v-card v-if="success">
                <v-toolbar color="indigo" dark >
                    <v-toolbar-title>Change Password</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-row >
                    <v-col cols="12" sm="12">
                        <v-chip class="ma-2" color="indigo darken-2" outlined >
                            <v-icon left>
                                mdi-account-outline
                            </v-icon>
                            Password changed. A new password is sent to your email inbox.
                        </v-chip>
                    </v-col>
                </v-row>
                <v-row class="row-card">
                    <v-col cols="12" sm="12" >
                        <v-btn color="indigo darken-2" class="ma-5 white--text" @click="closeDialog" >
                        Close
                        <v-icon right dark >close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                
            </v-card>

            <v-card v-if="!success">
                <v-toolbar color="indigo" dark >
                    <v-toolbar-title>Change Password</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                    <v-row >
                        <v-col cols="12" sm="12">
                            <v-chip class="ma-2" color="indigo darken-2" outlined >
                                <v-icon left>
                                    mdi-account-outline
                                </v-icon>
                                Please type in your username, email address and your new password
                            </v-chip>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="12" >
                            <v-text-field
                                label="Username"
                                type="text"
                                v-model="passwordChange.userName"
                                :error-messages="!$v.passwordChange.userName.required ? ['Username is required'] 
                                    : noUsernameOrEmail ? ['Your username or email is not found inside the system']
                                    : stillFirstLogin ? ['Your account never logined before, please check your email for first-time temporary password. If you could not find it, please contact admin.']
                                    :''"
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="12" >
                            <v-text-field
                                label="Email"
                                type="text"
                                v-model="passwordChange.email"
                                :error-messages="!$v.passwordChange.email.required ? ['Email is required'] :''"
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="row-card">
                        <v-col cols="12" sm="12" >
                            <v-text-field
                            :type="hidePassword ? 'password' : 'text'"
                            :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                            name="password"
                            label="New Password"
                            id="password"
                            v-model="newpass"
                            :error-messages="!$v.newpass.required ? ['Password is required'] 
                                            : !$v.newpass.sameAs ? ['Password not matched']
                                            : !$v.newpass.minLength ? ['Minimum 8 characters']
                                            : passwordcatch ? ['Your new password cannot be the same as the old or temporary password']
                                            :''"
                            @click:append="hidePassword = !hidePassword"/>
                        </v-col>
                    
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="12" >
                            <v-text-field
                            :type="hidePassword2 ? 'password' : 'text'"
                            :append-icon="hidePassword2 ? 'visibility_off' : 'visibility'"
                            name="password"
                            label="New Password Confirm"
                            id="password"
                            v-model="newpassconfirm"
                            :error-messages="!$v.newpassconfirm.required ? ['Password Confirm is required'] 
                                            : !$v.newpass.minLength ? ['Minimum 8 characters']
                                            : !$v.newpassconfirm.sameAs ? ['Password not matched']
                                            :''"
                            @click:append="hidePassword2 = !hidePassword2"/>
                        </v-col>
                    
                    </v-row> -->
                    <v-row class="row-card">
                        <v-col cols="12" sm="12" >
                            <v-btn color="indigo darken-2" class="ma-5 white--text" @click="changePassword" >
                            Change Password
                            <v-icon right dark >save</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                
            </v-card>
           
     </v-dialog>
    </div>
</template>


<script>
import 'vuejs-noty/dist/vuejs-noty.css'
import { validationMixin } from 'vuelidate'
import {  sameAs, required, minLength } from 'vuelidate/lib/validators'


export default {

  name: 'firstTimeLogin',
  mixins: [validationMixin],

    validations: {
        // newpass: {
        //     required,
        //     sameAs: sameAs('newpassconfirm'),
        //     minLength: minLength(8)
        // },
        // newpassconfirm: 
        // {
        //     required,
        //     sameAs: sameAs('newpass'),
        //     minLength: minLength(8)
        // },
        passwordChange: {
            userName: {required},
            email: { required }
        }
    },

  props: {
     value: Boolean
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
  data() {
    return {
        noUsernameOrEmail: false,
        stillFirstLogin: false,

        passwordcatch: false,
        hidePassword: true,
        hidePassword2: true,
        newpass: null,
        newpassconfirm: null,
        error: false,
        
        success: false,

        passwordChange:{
            userName: null,
            oldPassword: null,
            newPassword: null,
            email: null,
            type: 'changePassword'
        },
        rules: {
          min: v => v.length >= 8 || 'Min 8 characters'
        },
    }
  },
  created() {
    
  },
  methods: {
    closeDialog(){
        //this.$props.value= false;
        this.$emit('closedialog', false);
    },
    changePassword(){
        if(this.$v.$invalid){
            this.$v.$touch();
        }
        else{
            //this.passwordChange.userName = localStorage.getItem('username');
            //this.passwordChange.newPassword = this.newpassconfirm;

            this.listLoading = true
            let loader = this.$loading.show({
                    // Optional parameters
                    });

            var vm = this;
            const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

            this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/identity/cp/change-password',this.passwordChange)
            .then(response => {
                console.log("change password successfully" +response);
                vm.success = true;
                this.$emit('successChanged', true);
                loader.hide();
                vm.passwordcatch = false;
                vm.noUsernameOrEmail = false;
                vm.stillFirstLogin = false;
            })
            .catch(function (error) {
                loader.hide();
                vm.listLoading = false
                if(error.response.data.systemInformation[0].errorCode === "101"){
                    vm.passwordcatch = true;
                }
                if(error.response.data.systemInformation[0].errorCode === "104"){
                    vm.noUsernameOrEmail = true;
                }
                if(error.response.data.systemInformation[0].errorCode === "105"){
                    vm.stillFirstLogin = true;
                }
                console.error(error);
            });
        }
    }
  }
}
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

#search-btn {
    margin-left: 0px !important;
    margin-bottom: -30px ! important;
}

.popup{
    white-space: pre;
}

</style>