<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div class="app-container">
        <v-container>
            <v-card>
                <v-card-title>
                <v-icon size="28" class="pageicon">groups</v-icon> User List
                
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-row>
                    
                    <v-col cols="12" sm="8" class="text-right">
                        
                        <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                            
                    </v-col>
                    <v-col cols="12" sm="2" class="text-left">
                        <v-btn small color="warning" id="search-btn" class="ma-2 white--text" @click="getList" >
                                    Search
                                    <v-icon right dark >search</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
                
                
                </v-card-title>

                <v-data-table
                    :page="page"
                    :pageCount="numberOfPages"
                    :headers="headers"
                    :items="list"
                    :options.sync="options"
                    :server-items-length="totalItem"
                    :loading="listLoading"
                    :footer-props="footerProps"
                    
                >
                
                    
                    <template v-slot:[`item.setting`]="{ item }">
                        <v-btn small color="success" id="btn-list" 
                            class="ma-2 white--text btn-list" 
                            @click="goToUserFinance(item.id)" 
                            v-if="item.role !== 'Admin'">
                        Finance
                        <v-icon right dark >monetization_on</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
    </div>
  </v-container>
</template>

<script>

export default {
  name: 'UserList',
  components: {  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
        search: '',
        footerProps: {
            'items-per-page-options': [50, 100, 300, 1000, 9999]
        },
        headers: [
          
          { text: 'Name', value: 'name' , sortable: true},
          { text: 'Role', value: 'role' , sortable: true},
          { text: 'Intake', value: 'intakeIds' , sortable: true},
          { text: 'Setting', align: 'center', value: 'setting'},
        ],
      
      list: [],
      total: 0,
      listLoading: true,

      page: 1,
      totalItem: 0,
      numberOfPages: 0,
      options: {},

      intakesList: []

    }
  },
  watch: {
    search: {
        handler(newVal, oldVal) {
            // do something with the object
            sessionStorage.setItem("pp-finance-user-listing-settings", JSON.stringify(this.search));
        },
        deep: true,
    },
    options: {
      handler() {
        this.getList();
      },
    },
    deep: true,
  },
  mounted() {
    //this.getList();
  },
  created(){
      this.getIntakes();
      /* Check if got memory remember */
      if(sessionStorage.getItem("pp-finance-user-listing-settings") !== null){
          var vm = this;

          vm.search = JSON.parse(sessionStorage.getItem("pp-finance-user-listing-settings"));
      }
  },
  methods: {
    getIntakes(){
            this.listLoading = true
            let loader = this.$loading.show({
                    // Optional parameters
                    });
            
            var vm = this;
            const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

            this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/active',config)
                .then(response => {
                    
                    // vm.intakesList = [];
                    // let intakes = response.data.intakes
                    //vm.intakesList.push.apply(vm.intakesList,intakes.map(item => {return { intakeId: item.id, name: item.name}}));
        
                    vm.intakesList = response.data.intakes;
                    vm.getList();
                    vm.listLoading = false;
                    loader.hide();
                    
                })
                .catch(function (error) {
                    vm.listLoading = false
                    console.error(error);
                    loader.hide();
                });
    },
    getList() {
      this.listLoading = true
      var vm = this;

      const { page, itemsPerPage, sortBy, sortDesc, groupBy, groupDesc, multiSort } = this.options;
      let pageNumber = page - 1;

      const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
        };

      let loader = this.$loading.show({
                    // Optional parameters
                    
                    });

      let url = process.env.VUE_APP_MICROSERVICE_URL+'/identity/list/student?&page='+ pageNumber + '&size='+itemsPerPage+'&sort='+ sortBy +'&desc=' +sortDesc+'&search='+vm.search;
      
      this.$http.get(url,config)
        .then(response => {
            vm.list = response.data.list

            vm.list.map(item => {
                if(item.role === 'ROLE_ADMIN'){
                    item.role = 'Admin';
                }else if(item.role === 'ROLE_TEACHER'){
                    item.role = 'Teacher';
                }else if(item.role === 'ROLE_STAFF'){
                    item.role = 'Staff';
                }else if(item.role === 'ROLE_STUDENT'){
                    item.role = 'Student';
                }else {
                    item.role = 'Unknown';
                }
            });

            vm.list.map(item => {
                let intakesDesc = [];

                //Admin will skip this as intakeIds will be undefined
                if(item.intakeIds !== undefined ){
                    item.intakeIds.map(a => {
                    let intake = vm.intakesList.filter(b => b.id === a)

                    //found
                    if(intake.length>0){
                      intakesDesc.push(intake[0].name);
                    }
                    
                  })
                }
                
                item.intakeIds = intakesDesc;
                
            });

            vm.totalItem = response.data.totalItem;
            vm.numberOfPages = response.data.totalPage;
            vm.listLoading = false;
            loader.hide();
        })
        .catch(function (error) {
            vm.listLoading = false;
            loader.hide();
            console.error(error);
        });

    },
    goToUserFinance(id){
        var vm = this;
        let data = vm.list.filter(item => 
            item.id == id
        );
        this.$router.push({
            path: "finance/edit/"+id,
            params: { data }
        });
    }
  }
}
</script>

<style scoped>
.container{
    margin-top: 20px;
}
#btn-list {
  margin-left: 0px !important;
}
#search-btn {
    margin-left: 0px !important;
    margin-bottom: -30px ! important;
}
.pageicon{
  margin-right: 20px;
}
</style>
