<template>
   <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
              <v-card class="dashboardcard" >
                <v-card-title> <v-icon size="28" class="pageicon">campaign</v-icon>Announcement</v-card-title>
                <div v-for="(item, index) in list" :key="index">
                    <v-card class="pair-card annCard" elevation="8">
                    <v-toolbar dense color="light-blue darken-4" dark >
                        <v-btn icon>
                          <v-icon>bookmarks</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{item.title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title v-show="!$vuetify.breakpoint.xs">Posted on {{item.timestamp}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-title >  </v-card-title>
                    <v-row class="row-card" v-show="$vuetify.breakpoint.xs">
                        <v-col cols="12" sm="12" xs="12" >
                            Posted on {{item.timestamp}}
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="12" xs="12" >
                          <quill-editor
                                ref="myQuillEditor"
                                v-model="item.content"
                                :options="editorOption"
                                :disabled='disabled'
                            />
                        </v-col>
                    </v-row>
                    <div v-for="(al, a) in item.pdfDatas" :key="a">
                      <v-row class="row-card" v-if="al.expandable" >
                          <v-col cols="12" sm="12" xs="12" class="pdfExpand">
                            <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="$refs['myPdfComponent'+index+'' + a][0].print()">
                                                            print
                                                            <v-icon right dark >print</v-icon>
                                                        </v-btn>
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="downloadPDF(al.data,al.name)">
                                                            Download
                                                            <v-icon right dark >file_download</v-icon>
                                                        </v-btn>
                            <pdf 
                                :ref="`myPdfComponent${index}${a}`"
                                v-for="i in al.numPages"
                                :key="i"
                                :page="i" 
                                :src="al.data"
                                >
                            </pdf>
                          </v-col>
                      </v-row>
                      <v-row class="row-card" v-if="!al.expandable">
                          <v-col cols="12" sm="12" xs="12" >
                            <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                    <template v-slot:activator="{ on, attrs }">
                                       <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                    
                                                v-bind="attrs" v-on="on"
                                                    @click="al.dialogAction = true">
                                                        <span style="white-space: normal;">
                                                        {{ al.name }}
                                                        </span>
                                                        <v-icon right dark >auto_stories</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                        <v-toolbar color="indigo" dark dense>
                                            <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                        </v-toolbar>
                                        <v-divider></v-divider>
                                        <v-card-text style="height: 800px;">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="12" >
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="$refs['myPdfComponent'+index+'' + a][0].print()">
                                                            print
                                                            <v-icon right dark >print</v-icon>
                                                        </v-btn>
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="downloadPDF(al.data,al.name)">
                                                            Download
                                                            <v-icon right dark >file_download</v-icon>
                                                        </v-btn>
                                                        <pdf 
                                                            :ref="`myPdfComponent${index}${a}`"
                                                            v-for="i in al.numPages"
                                                            :key="i"
                                                            :page="i" 
                                                            :src="al.data">
                                                        </pdf>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="al.dialogAction = false"
                                        >
                                            Close
                                        </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                          </v-col>
                      </v-row>
                    </div>
                        
                    </v-card>
                </div>
                <div>
                    <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                    :total-visible="7"
                    ></v-pagination>
                </div>
              </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import pdf from 'vue-pdf';
import {Vue} from 'vue';

export default {
  name: "Dashboard",
  components: {
    pdf
  },
  data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      editorOption: {
          readOnly: true,
          theme: 'snow',
          modules: {
              toolbar : false
          }
      },
      disabled: true,
      page: 1,
      totalItem: 0,
      numberOfPages: 0,
    };
  },
  created() {
    
  },
  mounted(){
    this.getList();
  },
  computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
  },
  watch: {
      page: {
         handler() {
            this.getList();
         },
      },
      deep: true,
  },
  methods: {
    downloadPDF(e,filename){
            
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        //var blob = new Blob([e], { type: "application/pdf" }),
        var blob = this.dataURItoBlob(e);
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    },
    dataURItoBlob(dataURI){
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
    },
    getList() {
      this.listLoading = true
      var vm = this;

      
      const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
        };

      let loader = this.$loading.show({
                    // Optional parameters
                    //container: this.$refs.formContainer,
                    //isFullPage: false
                    });
      let listpage = this.page - 1;
      let url = process.env.VUE_APP_MICROSERVICE_URL+'/announcement/list/active?&category=General&page='+listpage+'&size=5&sort=postedOn&desc=true';
      
      this.$http.get(url,config)
        .then(response => {
            vm.list = response.data.list
            vm.list.map(item => {
              item.timestamp = moment(item.postedOn).format('DD/MM/YYYY hh:mm a');
              item.pdfDatas.map(a => {
                vm.$set(a,'dialogAction',false);
              })

            });
            vm.totalItem= response.data.totalItem;
            vm.numberOfPages = response.data.totalPage;
            loader.hide();
            vm.listLoading = false;
        })
        .catch(function (error) {
            vm.listLoading = false;
            loader.hide();
            console.error(error);
        });

    }
  }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
.v-toolbar__title {
    font-size: 1rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dashboardcard{
  padding-bottom: 30px;
}
.pdfExpand{
    border: 1px solid #0c0c0c;
    margin-top: 20px;
    margin-bottom: 20px;
}
.v-application .v-card .annCard{

  /* box-shadow: 
    0px 8px 9px -5px rgb(0 0 0 / 20%), 
    0px 15px 22px 2px rgb(0 0 0 / 14%), 
    0px 6px 28px 5px rgb(0 0 0 / 12%) !important; */
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}
@media (min-width: 1264px){
    /deep/ iframe{
        width: 500px;
        height: 300px;
    }
}


</style>
