<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <FirstTimeLoginDialog @closedialog="onFirstTimeLoginClose" @successChanged="clearPassword" v-model="showFirstLogin"></FirstTimeLoginDialog> 
        <ChangePasswordDialog @closedialog="onChangePasswordClose" @successChanged="clearPassword" v-model="showChangePassword"></ChangePasswordDialog> 
        
        <v-col cols="5" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/nkl-logo.png" contain max-height="300" max-width="550"></v-img>
            <p style="font-size:60px">NKL Portal</p>
          </div>
        </v-col>
        <v-col cols="12" lg="7" class="login-part d-flex align-center justify-center">
          
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-snackbar
                    v-model="showResult"
                    :timeout="2000"
                    top>
                    {{ result }}
                  </v-snackbar>
                <v-tabs grow>
                  <v-tab :href="`#tab-login`">
                    LOGIN
                  </v-tab>
                </v-tabs>
                
                  
                  
                  <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          
                          
                          <v-form>
                            <v-col>
                              <v-text-field
                                append-icon="person"
                                name="login"
                                label="Login"
                                type="text"
                                v-model="userName"
                                :error="error"
                                :rules="[rules.required]"/>
                              <v-text-field
                                :type="hidePassword ? 'password' : 'text'"
                                :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                                name="password"
                                label="Password"
                                id="password"
                                :rules="[rules.required]"
                                v-model="password"
                                :error="error"
                                @click:append="hidePassword = !hidePassword"/>

                            </v-col>
                            <v-col class="d-flex justify-space-between">
                                <v-btn large block color="primary" @click="login" :loading="loading">Login</v-btn>
                            </v-col>
                            <v-col class="d-flex justify-space-between">
                                <v-btn large text class="text-capitalize primary--text" @click="forgetPassword">Forget Password</v-btn>
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>

                  

              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2023 <a class="text-decoration-none">Northen Kuala Lumpur International College</a>,  All rights reserved.</div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import FirstTimeLoginDialog from "../passwordchange/FirstTimeLogin-dialog";
import ChangePasswordDialog from "../passwordchange/ChangePassword-dialog";

  export default {
    name: 'Login',
    components: { FirstTimeLoginDialog, ChangePasswordDialog },
    data() {
      return {

        email: 'admin@admin.com',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],

        showFirstLogin: false,
        showChangePassword: false,
        showPasswordExpired: false,

        loading: false,
        userName: '',
        password: '',
        hidePassword: true,
        error: false,
        showResult: false,
        result: '',
        roles: [],

        rules: {
          required: value => !!value || 'Required.'
        }
      }
    },
    methods: {
      forgetPassword(){
        this.showChangePassword = true;
      },
      onChangePasswordClose(){
        this.showChangePassword = false;
      },

      clearPassword(){
        this.password = null;
      },
      onFirstTimeLoginClose(){
        this.showFirstLogin = false;
      },
      onPasswordExpiredClose(){
        this.showPasswordExpired = false;
      },
      login() {
        const vm = this;

        if (!vm.userName || !vm.password) {

          vm.result = "Username and Password can't be empty.";
          vm.showResult = true;

          return;
        }
        
        let loader = this.$loading.show({
                    });

        this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/auth/api/auth/login', {
                          username: vm.userName,
                          password: vm.password
                      })
          .then(response => {
              //let is_admin = response.data.user.is_admin

              if(response.data.avatar != undefined){
                localStorage.setItem('avatar',response.data.avatar);
                vm.$localStorage.avatar = response.data.avatar;
              }
              
              localStorage.setItem('name',response.data.name)
              vm.$localStorage.name = response.data.name;

              localStorage.setItem('username',response.data.username)
              vm.roles = response.data.roles;
              localStorage.setItem('roles',JSON.stringify(response.data.roles))
              localStorage.setItem('jwt',response.data.token)
              localStorage.setItem('id',response.data.id)
              
              if(response.data.roles.filter(item => item === "ROLE_ADMIN").length > 0){

                /*If logined user is admin */
                localStorage.removeItem('firstLogin');
                vm.removeRoute();
                if (localStorage.getItem('jwt') != null){
                      vm.$emit('loggedIn')
                      if(vm.$route.params.nextUrl != null){
                          vm.$router.push(vm.$route.params.nextUrl)
                      }
                      else {
                          vm.$router.push('/dashboard')
                      }
                }
              }else{
                /*If logined user is not admin */

                /* check everything */
                if(response.data.passwordChange === -1 || response.data.passwordChange === -2){
                    // window.localStorage.removeItem('username');
                    // window.localStorage.removeItem('roles');
                    // window.localStorage.removeItem('jwt');

                    // window.localStorage.setItem('authenticated', false);
                    localStorage.setItem('firstLogin',true)
                    vm.showFirstLogin = true;
                }
                // else if(response.data.passwordexpired){
                //     vm.showPasswordExpired = true;
                // }
                else if(response.data.accountLocked){
                  localStorage.setItem('firstLogin',true)
                  vm.result = "Your account is locked. Please contact administrator";
                  vm.showResult = true;
                  vm.error = true;
                }
                // else if(!response.data.operator){
                //   vm.result = "Your account is disabled. Please contact administrator";
                //   vm.showResult = true;
                //   vm.error = true;
                // }
                else{
                    localStorage.removeItem('firstLogin');
                    vm.removeRoute();
                    if(localStorage.getItem('jwt') != null){
                          vm.$emit('loggedIn')
                          if(vm.$route.params.nextUrl != null){
                              vm.$router.push(vm.$route.params.nextUrl)
                          }
                          else {
                              vm.$router.push('/dashboard')
                          }
                    }
                }

              }
              loader.hide();
          })
          .catch(function (error) {
              loader.hide();
              console.error(error);
              vm.error = true;
              vm.result = "Username or Password is incorrect.";
              vm.showResult = true;
              vm.showFirstLogin = false;
              vm.showChangePassword = false;
              vm.showPasswordExpired = false;
          });


      },
      removeRoute(){
        const vmm = this;
          vmm.$router.options.routes = vmm.$router.options.routes.filter( route => {

              route.hidden = undefined;
              if(route.roles!=null){

                  const intersection = route.roles.filter(element => vmm.roles.includes(element));
                    if(intersection.length != 0){
                      return route;
                    }else{
                      route.hidden = true;
                      return route;
                    }

              }else if(route.children != null){
                route.children = route.children.filter(children => {

                  if(children.roles != null){

                    const childsection = children.roles.filter(element => vmm.roles.includes(element));
                    if(childsection.length != 0){
                      return children;
                    }else{
                      route.hidden = true;
                      return route;
                    }
                  }else{
                    return children;
                  }
                })

                return route;

              }else{
                return route;
              }

          })

      }
    }
    // created() {
    //   if (window.localStorage.getItem('authenticated') === 'true') {
    //     this.$router.push('/dashboard');
    //   }
    // }
  }

</script>

<style src="./Login.scss" lang="scss"/>
