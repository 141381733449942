<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
            <v-card>
              <v-card-title> <v-icon size="28" class="pageicon">note_add</v-icon> Add Intake</v-card-title>
                  <v-row>
                      <v-col cols="12" sm="12" class="text-right" >
                          <v-btn color="success" class="ma-5 white--text" @click="addNewIntake" >
                          Add Intake
                          <v-icon right dark >save</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
                  <v-row class="row-card">
                      <v-col cols="12" sm="4" >
                          <v-text-field
                              label="Name"
                              v-model="intake.name"
                              required
                              @output="$v.intake.name.$touch()"
                              @blur="$v.intake.name.$touch()"
                              :error-messages="!$v.intake.name.required ? ['Name is required'] : ''"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" >
                            <v-select :items="courses" item-text="name" item-value="id"
                                v-model="intake.courseId" 
                                label="Course" outlined attach
                                :error-messages="!$v.intake.courseId.required ? ['Course is required'] : ''"
                                v-on:change="courseTrigger"
                            >
                            </v-select>
                      </v-col>
                      <v-col cols="12" sm="4" >
                            <v-select :items="fullorpart"
                                v-model="intake.fullPartTime" 
                                label="Full/Part time" outlined attach
                                :error-messages="!$v.intake.fullPartTime.required ? ['Full or Part time is required'] : ''"
                            >
                            </v-select>
                      </v-col>
                  </v-row>
                  <v-row class="row-card">
                      <v-col cols="12" sm="4" >
                        <v-menu
                            ref="menustart"
                            v-model="menustart"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="intake.start"
                                label="Start Date"
                                readonly
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                :error-messages="!$v.intake.start.required ? ['Start date is required'] : ''"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="intake.start"
                                @input="menustart = false"
                            ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="4" >
                            <v-menu
                                ref="menuend"
                                v-model="menuend"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="intake.end"
                                    label="End Date"
                                    readonly
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="!$v.intake.end.required ? ['End date is required'] 
                                                : !$v.intake.end.seCheck ? ['End date must be after start date'] 
                                                :''"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="intake.end"
                                    @input="menuend = false"
                                ></v-date-picker>
                            </v-menu>
                      </v-col>
                  </v-row>
                  
                  <div v-if="courseType === 'MQA'">
                    <div  v-for="(fl, f) in intake.semesters" :key="f">
                        <v-card class="pair-card" elevation="5" >
                        <v-toolbar dense color="indigo" dark >
                            <v-toolbar-title>Semester {{f+1}}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                            <v-row class="row-card">
                                
                                <v-col cols="12" sm="4" xs="4" >
                                    <v-menu
                                        :ref="fl.datemenustart"
                                        v-model="fl.datemenustart"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="fl.start"
                                            label="Start Date"
                                            readonly
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="!$v.intake.semesters.$each[f].start.required ? ['Start date is required'] 
                                                : !$v.intake.semesters.$each[f].start.seStartDateCheck ? ['Start date must be between semester start and end date'] 
                                                : ''"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="fl.start"
                                            @input="fl.datemenustart = false"
                                        ></v-date-picker>
                                    </v-menu>
                                    
                                </v-col>
                                <v-col cols="12" sm="4" xs="4" class="d-flex align-center">
                                    <v-menu
                                        :ref="fl.datemenuend"
                                        v-model="fl.datemenuend"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="fl.end"
                                            label="Start Date"
                                            readonly
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            :error-messages="!$v.intake.semesters.$each[f].end.required ? ['End date is required'] 
                                                : !$v.intake.semesters.$each[f].end.seCheck ? ['End date must be after start date'] 
                                                : !$v.intake.semesters.$each[f].end.seEndDateCheck ? ['End date must be between semester start and end date'] 
                                                : ''"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="fl.end"
                                            @input="fl.datemenuend = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="2" xs="2" class="text-right">
                                    <v-btn class="mx-2" fab dark small color="primary" v-if="intake.semesters.length != 0" @click="removePair(f)">
                                        <v-icon dark>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" sm="4" md="4" >
                                    <v-file-input
                                        v-model="fileupload"
                                        accept=".pdf"
                                        prepend-icon="style"
                                        label="Upload Timetable"
                                        @change="addPDF($event,f)"
                                    ></v-file-input>
                                </v-col>
                                <v-col cols="12" sm="8" md="8"  >
                                    <v-row v-for="(al, a) in fl.timeTablePdfDatas" :key="a">
                                    
                                                
                                            <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                                <template v-slot:activator="{ on, attrs }">
                                                
                                                    <v-col cols="12" sm="8" md="8"  >
                                                        <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                                
                                                            v-bind="attrs" v-on="on"
                                                                @click="al.dialogAction = true">
                                                                    <span style="white-space: normal;">
                                                                    {{ al.name }}
                                                                    </span>
                                                                    <v-icon right dark >info</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="12" sm="2" md="2"  >
                                                        <v-btn small color="red" class="ma-2 white--text" 
                                                            @click="deletePDF(a,f)">
                                                                <span style="white-space: normal;">
                                                                Delete
                                                                </span>
                                                                <v-icon right dark >delete_forever</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    
                                                    
                                                    
                                                </template>
                                                <v-card>
                                                    <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                                    <v-toolbar color="indigo" dark dense>
                                                        <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                                    </v-toolbar>
                                                    <v-divider></v-divider>
                                                    <v-card-text style="height: 800px;">
                                                        <v-container>
                                                            <v-row>
                                                                <v-col cols="12" sm="12" md="12" >
                                                                    <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                        @click="$refs['myPdfComponent'+f+''+ a][0].print()">
                                                                        print
                                                                        <v-icon right dark >print</v-icon>
                                                                    </v-btn>
                                                                    <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                        @click="downloadPDF(al.data,al.name)">
                                                                        Download
                                                                        <v-icon right dark >file_download</v-icon>
                                                                    </v-btn>
                                                                    <pdf 
                                                                        :ref="`myPdfComponent${f}${a}`"
                                                                        v-for="i in al.numPages"
                                                                        :key="i"
                                                                        :page="i" 
                                                                        :src="al.data">
                                                                    </pdf>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                    <v-divider></v-divider>
                                                    <v-card-actions>
                                                    <v-btn
                                                        color="blue darken-1"
                                                        text
                                                        @click="al.dialogAction = false"
                                                    >
                                                        Close
                                                    </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                    
                                    </v-row>
                                </v-col>

                            </v-row>

                            
                        </v-card>
                    </div>
                  </div>

                <v-row class="row-card">
                    <v-col cols="12" sm="4" >
                        <v-btn color="indigo" v-if="courseType === 'MQA'" class="ma-5 white--text" @click="addPair()" >
                        Add Semester
                        <v-icon right dark >note_add</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="row-card" v-if="courseType === 'OTHER'">
                    <v-col cols="12" sm="4" md="4" >
                        <v-file-input
                            v-model="fileupload"
                            accept=".pdf"
                            prepend-icon="style"
                            label="Upload Timetable"
                            @change="addPDFOther($event)"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="8" md="8"  >
                        <v-row v-for="(al, a) in intake.timeTablePdfDatas" :key="a">
                        
                                    
                                <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                    <template v-slot:activator="{ on, attrs }">
                                    
                                        <v-col cols="12" sm="8" md="8"  >
                                            <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                    
                                                v-bind="attrs" v-on="on"
                                                    @click="al.dialogAction = true">
                                                        <span style="white-space: normal;">
                                                        {{ al.name }}
                                                        </span>
                                                        <v-icon right dark >info</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="2"  >
                                            <v-btn small color="red" class="ma-2 white--text" 
                                                @click="deletePDFOther(a)">
                                                    <span style="white-space: normal;">
                                                    Delete
                                                    </span>
                                                    <v-icon right dark >delete_forever</v-icon>
                                            </v-btn>
                                        </v-col>
                                        
                                        
                                        
                                    </template>
                                    <v-card>
                                        <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                        <v-toolbar color="indigo" dark dense>
                                            <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                        </v-toolbar>
                                        <v-divider></v-divider>
                                        <v-card-text style="height: 800px;">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="12" >
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="$refs['myOtherPdfComponent'+ a][0].print()">
                                                            print
                                                            <v-icon right dark >print</v-icon>
                                                        </v-btn>
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="downloadPDF(al.data,al.name)">
                                                            Download
                                                            <v-icon right dark >file_download</v-icon>
                                                        </v-btn>
                                                        <pdf 
                                                            :ref="`myOtherPdfComponent${a}`"
                                                            v-for="i in al.numPages"
                                                            :key="i"
                                                            :page="i" 
                                                            :src="al.data">
                                                        </pdf>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="al.dialogAction = false"
                                        >
                                            Close
                                        </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                        
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import 'vuejs-noty/dist/vuejs-noty.css'
import Noty from 'noty';
import { validationMixin } from 'vuelidate';
import { required, requiredUnless } from 'vuelidate/lib/validators';
import pdf from 'vue-pdf';

const seCheck = (value, vm) => {
  
    if(vm.start != null && vm.end != null && new Date(vm.end) < new Date(vm.start)){
        return false;
    }else{
        return true;
    }
}

function seStartDateCheck (value, vm) {
    if(this.intake.start != null && vm.start != null  && this.intake.end != null
        && (new Date(vm.start) < new Date(this.intake.start) || new Date(vm.start) > new Date(this.intake.end) )
    )
    {
        return false;
    }else{
        return true;
    }
}

function seEndDateCheck (value, vm) {
    if(this.intake.start != null && vm.end != null && this.intake.end != null
        && (new Date(vm.end) < new Date(this.intake.start) || new Date(vm.end) > new Date(this.intake.end) )
    )
    {
        return false;
    }else{
        return true;
    }
}


export default {
  components: {
    pdf
  },
  mixins: [validationMixin],
  validations: {
    intake:{
        name: { required },
        courseId: { required },
        start: { required },
        end: { 
            required,
            seCheck
        },
        fullPartTime: { required },
        semesters: {
            $each: {
                start: { 
                    required,
                    seStartDateCheck
                    // required : requiredUnless(function() {
                    //     return this.intake.semesters.length == 0
                    // }),
                    // seStartDateCheck : requiredUnless(function() {
                    //     return this.intake.semesters.length == 0
                    // })
                },
                end: { 
                        required,
                        seCheck,
                        seEndDateCheck
                     }
            }
        }
    }
  },
  name: "Add-Intake",
  data() {
    return {
        fileupload: null,
        menustart: false,
        menuend: false,
        intake: {
            name: null,
            courseId: null,
            start: null,
            end: null,
            semesters: [],
            fullPartTime: null,
            timeTablePdfDatas: []
        },
        courseType: null,
        courses : [],
        fullorpart: ['Full Time', 'Part Time']
        
    };
  },
  created() {
      this.getCourses();
  },
  methods: {
        downloadPDF(e,filename){
            
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            //var blob = new Blob([e], { type: "application/pdf" }),
            var blob = this.dataURItoBlob(e);
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        dataURItoBlob(dataURI){
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            var ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            var blob = new Blob([ab], {type: mimeString});
            return blob;
        },
        deletePDFOther(index){
            this.intake.timeTablePdfDatas.splice(index,1);
        },
        deletePDF(index,semesterindex){
            this.intake.semesters[semesterindex].timeTablePdfDatas.splice(index,1);
        },
        addPDF(e,semesterindex){
            var vm = this;
            if(e !== null){
                const selectedImage = e;
                const readerBinary = new FileReader();
                const readerDataUrl = new FileReader();
                var numpage= 1;
                readerBinary.onload= (f=> {
                    numpage = f.target.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                    readerDataUrl.readAsDataURL(selectedImage);
                    
                });
                readerDataUrl.onload= (f=> {
                    let pdfdata = { 
                            name: e.name, 
                            data: f.target.result,
                            dialogAction: false,
                            numPages: numpage,
                            expandable: false
                    };
                    vm.intake.semesters[semesterindex].timeTablePdfDatas.push(pdfdata);
                    vm.fileupload = null;
                });
                readerBinary.readAsBinaryString(selectedImage);
            }
        },
        addPDFOther(e){
            var vm = this;
            if(e !== null){
                const selectedImage = e;
                const readerBinary = new FileReader();
                const readerDataUrl = new FileReader();
                var numpage= 1;
                readerBinary.onload= (f=> {
                    numpage = f.target.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                    readerDataUrl.readAsDataURL(selectedImage);
                    
                });
                readerDataUrl.onload= (f=> {
                    let pdfdata = { 
                            name: e.name, 
                            data: f.target.result,
                            dialogAction: false,
                            numPages: numpage,
                            expandable: false
                    };
                    vm.intake.timeTablePdfDatas.push(pdfdata);
                    vm.fileupload = null;
                });
                readerBinary.readAsBinaryString(selectedImage);
            }
        },
        courseTrigger(){
            var vm = this;
            let course = this.courses.filter(item => item.id === vm.intake.courseId)
            if(course[0].type === 'MQA'){
                vm.courseType = 'MQA';
            }else{
                vm.courseType = 'OTHER';
                //vm.intake.semesters = [];
            }
        },
        removePair(i){
            this.intake.semesters.splice(i, 1);
        },
        addPair(){
            let i ={
                start: null,
                end: null,
                semester: null,
                datemenustart: false,
                datemenuend: false,
                timeTablePdfDatas: []
            };
            this.intake.semesters.push(i);
        },
        getCourses(){
            this.listLoading = true
            let loader = this.$loading.show({
                    // Optional parameters
                    });
            
            var vm = this;
            const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

            this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/course/get/all',config)
                .then(response => {
                    
                    vm.courses = response.data.courses;

                    vm.listLoading = false;
                    loader.hide();
                    
                })
                .catch(function (error) {
                    vm.listLoading = false
                    console.error(error);
                    loader.hide();
                });
        },
        addNewIntake(){
            if(this.courseType === 'OTHER'){
                this.intake.semesters = [];
            }

            if(this.courseType === 'MQA'){
                this.intake.semesters.map((currElement, index) =>{
                    currElement.semester = index+1;
                })
                this.intake.timeTablePdfDatas = [];
            }
                
            if(this.$v.$invalid){
                this.$v.$touch();
            }
            else{

                this.listLoading = true
                let loader = this.$loading.show({
                    // Optional parameters
                    //container: this.$refs.formContainer,
                    //isFullPage: false
                    });

                var vm = this;
                const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

                this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/intake/post?userName='+localStorage.getItem('username'),this.intake,config)
                .then(response => {

                    
                    loader.hide();

                    //notification of successfully saved
                    var notification = new Noty({
                        killer: false,
                        timeout: 5000,
                        type: 'success',
                        text: 'Intake ' +vm.intake.name+ ' is created.',
                        theme: 'metroui',
                        layout: 'topRight'
                    })
                    notification.show();
                    vm.$router.push({
                        path: "/intake-list"
                    });
                })
                .catch(function (error) {
                    loader.hide();
                    vm.listLoading = false;
                    console.error(error);
                });
            }
        }
   }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
.v-toolbar__title {
    font-size: 1rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
