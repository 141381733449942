import axios from 'axios';
import {router} from '../Routes.js'
import Noty from 'noty';

export default function setup() {
    var notification = new Noty({
        killer: false,
        timeout: 6000,
        type: 'error',
        text: 'Session expired, please relogin.',
        theme: 'sunset',
        layout: 'topRight'
    });

    
    axios.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
      }, function (error) {
        // Do something with response error
        if(error.response == undefined){
            let notificationNetworkError = new Noty({
                killer: false,
                timeout: 6000,
                type: 'error',
                text: 'Network error. Please check your internet connection. If issues persist, please contact Pintar admin.',
                theme: 'sunset',
                layout: 'topRight'
            })
            notificationNetworkError.show();
        }else{
            if(error.response.status === 401 && error.response.data.errorMessage === 'JWT-Invalid'){
                localStorage.removeItem('roles');
                localStorage.removeItem('jwt');
                localStorage.removeItem('username');
                localStorage.removeItem('avatar');
                localStorage.removeItem('id');
                localStorage.removeItem('name');
                
                notification.show();
    
    
                router.push({
                    path: "/login",
                });
            }
        }
        return Promise.reject(error);
      });
}