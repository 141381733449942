import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import Typography from '@/pages/Typography/Typography'
import Tables from '@/pages/Tables/Basic'
import Notifications from '@/pages/Notifications/Notifications'
import Icons from '@/pages/Icons/Icons'
import Charts from '@/pages/Charts/Charts'
import Maps from '@/pages/Maps/Google'
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";

import Registration from '@/pages/Registration/Registration';
import Userlist from '@/pages/Registration/Userlist';
import Usercontroler from '@/pages/Registration/Usercontrol';
import Useredit from '@/pages/Registration/Useredit';

import Userprofile from '@/pages/Userprofile/Userprofile';

import AddAnnouncement from '@/pages/Announcement/AddAnn';
import ListAnnouncement from '@/pages/Announcement/AnnouncementList';
import EditAnnouncement from '@/pages/Announcement/EditAnn';

import AddCourse from '@/pages/Course/AddCourse';
import ListCourse from '@/pages/Course/CourseList';
import EditCourse from '@/pages/Course/EditCourse';

import AddIntake from '@/pages/Intake/AddIntake';
import ListIntake from '@/pages/Intake/IntakeList';
import EditIntake from '@/pages/Intake/EditIntake';

import AdminTranscript from '@/pages/Transcript/adminTranscript';

import AdminFinanceUserList from '@/pages/Finance/adminFinanceUserListing';
import AdminFinanceUserEdit from '@/pages/Finance/AdminFinanceUserEdit';


import StudentCourseMain from '@/pages/Course/StudentCourseMain';
import StudentTranscriptMain from '@/pages/Transcript/StudentTranscriptMain';
import StudentTranscriptResult from '@/pages/Transcript/StudentTranscriptResult';
import StudentFinanceMain from '@/pages/Finance/StudentFinanceMain';
import StudentFinanceDetails from '@/pages/Finance/StudentFinanceUser';

export const router =  new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      redirect: '/dashboard',
      component: Layout
      
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      hidden:true
      
    },
    {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [

      {
        path: 'course/main',
        name: 'Student Course Main',
        component: StudentCourseMain,
        meta: { requiresAuth: true,title: 'Student Course Main'}
      },

      {
        path: 'transcript/main',
        name: 'Student Transcript Main',
        component: StudentTranscriptMain,
        meta: { requiresAuth: true,title: 'Student Transcript Main'}
      },
      {
        path: 'transcript/result',
        name: 'Student Transcript Result',
        component: StudentTranscriptResult,
        meta: { requiresAuth: true,title: 'Student Transcript Result'}
      },
      {
        path: 'finance/main',
        name: 'Student Finance Main',
        component: StudentFinanceMain,
        meta: { requiresAuth: true,title: 'Student Finance Main'}
      },
      {
        path: 'finance/details',
        name: 'Student Finance Details',
        component: StudentFinanceDetails,
        meta: { requiresAuth: true,title: 'Student Finance Details'}
      },



      {
        path: 'finance',
        name: 'Admin Finance List',
        component: AdminFinanceUserList,
        meta: { requiresAuth: true,title: 'Admin Finance Student List'}
      },
      {
        path: 'finance/edit/:id(.*)',
        name: 'User Finance',
        component: AdminFinanceUserEdit,
        meta: { requiresAuth: true,title: 'User Finance Edit'}
      },
      {
        path: 'transcript',
        name: 'Admin Transcript',
        component: AdminTranscript,
        meta: { requiresAuth: true,title: 'Admin Transcript'}
      },
      {
        path: 'add-intake',
        name: 'Add Intake',
        component: AddIntake,
        meta: { requiresAuth: true,title: 'Add Intake'}
      },
      {
        path: 'intake-list',
        name: 'Intake List',
        component: ListIntake,
        meta: { requiresAuth: true,title: 'Intake List'}
      },
      {
        path: 'edit-intake/:id(.*)',
        name: 'Edit Intake',
        component: EditIntake,
        meta: { requiresAuth: true,title: 'Intake Edit'}
      },
      {
        path: 'add-course',
        name: 'Add Course',
        component: AddCourse,
        meta: { requiresAuth: true,title: 'Add Course'}
      },
      {
        path: 'course-list',
        name: 'Course List',
        component: ListCourse,
        meta: { requiresAuth: true,title: 'Course List'}
      },
      {
        path: 'edit-course/:id(.*)',
        name: 'Edit Course',
        component: EditCourse,
        meta: { requiresAuth: true,title: 'Course Edit'}
      },
      {
        path: 'add-announcement',
        name: 'Add Announcement',
        component: AddAnnouncement,
        meta: { requiresAuth: true,title: 'Add Announcement'}
      },
      {
        path: 'announcement-list',
        name: 'Announcement List',
        component: ListAnnouncement,
        meta: { requiresAuth: true,title: 'Announcement List'}
      },
      {
        path: 'edit-announcement/:id(.*)',
        name: 'Edit Announcement',
        component: EditAnnouncement,
        meta: { requiresAuth: true,title: 'Announcement Edit'}
      },

      {
        path: 'user-profile',
        name: 'User Profile',
        component: Userprofile,
        meta: { requiresAuth: true,title: 'User Profile'}
      },
      {
        path: 'registration',
        name: 'User Registration',
        component: Registration,
        meta: { requiresAuth: true,title: 'User Registration'}
      },
      {
        path: 'user-list',
        name: 'User List',
        component: Userlist,
        meta: { requiresAuth: true,title: 'User List'}
      },
      {
        path: 'user-control',
        name: 'User Control',
        component: Usercontroler,
        meta: { requiresAuth: true,title: 'User Control'}
      },
      {
        path: 'user-edit/:id(.*)',
        name: 'User Edit',
        component: Useredit,
        meta: { requiresAuth: true,title: 'User Edit'}
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true,title: 'Dashboard', icon: 'mdi-home' }
      },


      
      {
        path: 'typography',
        name: 'Typography',
        component: Typography,
        meta: { requiresAuth: true,title: 'Typography', icon: 'mdi-format-size' }
      },
      {
        path: 'test',
        name: 'Test',
        component: Typography,
        meta: { requiresAuth: true,title: 'Test', icon: 'mdi-format-size' }
      },
      {
        path: 'tables',
        name: 'Tables',
        component: Tables,
        meta: { requiresAuth: true,title: 'Tables', icon: 'mdi-grid-large' }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications,
        meta: { requiresAuth: true,title: 'Notifications', icon: 'mdi-bell-outline' }
      },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons,
        meta: { requiresAuth: true,title: 'Notifications', icon: 'mdi-circle-small' }
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'maps',
        name: 'Maps',
        component: Maps
      },
    ],
  },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ],
});

Vue.use(Router);

router.beforeEach((to, from, next) => {

  
      if(to.path == '/login' && localStorage.getItem('jwt') !== null && !localStorage.getItem('firstLogin')){
        next({ name: 'Dashboard'});
      }
      else if(to.matched.some(record => record.meta.requiresAuth)) {
          if (localStorage.getItem('jwt') == null || localStorage.getItem('firstLogin')) {
              next({
                  path: '/login',
                  params: { nextUrl: to.fullPath }
              })
          } else {
              let user = JSON.parse(localStorage.getItem('user'))
              if(to.matched.some(record => record.meta.is_admin)) {
                  if(user.is_admin == 1){
                      next()
                  }
                  else{
                      next({ name: 'Dashboard'})
                  }
              }else {
                  next()
              }
          }
      } else if(to.matched.some(record => record.meta.guest)) {
          next()
          
      }else {
          next()
      }
  
  
})
