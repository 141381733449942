var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"formContainer"},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"600px","content-class":"dialogbg"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.success)?_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dark":""}},[_c('v-toolbar-title',[_vm._v("First Time Login / After password change")]),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo darken-2","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-outline ")]),_vm._v(" Password changed. Please close this and relogin. ")],1)],1)],1),_c('v-row',{staticClass:"row-card"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticClass:"ma-5 white--text",attrs:{"color":"indigo darken-2"},on:{"click":_vm.closeDialog}},[_vm._v(" Close "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("close")])],1)],1)],1)],1):_vm._e(),(!_vm.success)?_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dark":""}},[_c('v-toolbar-title',[_vm._v("First Time Login / After password change")]),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo darken-2","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-outline ")]),_vm._v(" You are required to change your password after your first login or password change ")],1)],1)],1),_c('v-row',{staticClass:"row-card"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"type":_vm.hidePassword ? 'password' : 'text',"append-icon":_vm.hidePassword ? 'visibility_off' : 'visibility',"name":"password","label":"New Password","id":"password","error-messages":!_vm.$v.newpass.required ? ['Password is required'] 
                                        : !_vm.$v.newpass.sameAs ? ['Password not matched']
                                        : !_vm.$v.newpass.minLength ? ['Minimum 8 characters']
                                        : _vm.passwordcatch ? ['Your new password cannot be the same as the old or temporary password']
                                        :''},on:{"click:append":function($event){_vm.hidePassword = !_vm.hidePassword}},model:{value:(_vm.newpass),callback:function ($$v) {_vm.newpass=$$v},expression:"newpass"}})],1)],1),_c('v-row',{staticClass:"row-card"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"type":_vm.hidePassword2 ? 'password' : 'text',"append-icon":_vm.hidePassword2 ? 'visibility_off' : 'visibility',"name":"password","label":"New Password Confirm","id":"password","error-messages":!_vm.$v.newpassconfirm.required ? ['Password Confirm is required'] 
                                        : !_vm.$v.newpass.minLength ? ['Minimum 8 characters']
                                        : !_vm.$v.newpassconfirm.sameAs ? ['Password not matched']
                                        :''},on:{"click:append":function($event){_vm.hidePassword2 = !_vm.hidePassword2}},model:{value:(_vm.newpassconfirm),callback:function ($$v) {_vm.newpassconfirm=$$v},expression:"newpassconfirm"}})],1)],1),_c('v-row',{staticClass:"row-card"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticClass:"ma-5 white--text",attrs:{"color":"indigo darken-2"},on:{"click":_vm.changePassword}},[_vm._v(" Change Password "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("save")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }