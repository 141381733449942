import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import config from '@/config'
import 'font-awesome/css/font-awesome.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#7AB9FF',
                secondary: '#FF5C93',
                warning: '#e0951c',
                error: '#ff4081',
            }
        }
    }
});
