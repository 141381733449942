<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div class="app-container">
        <v-container>
            <v-card>
                <v-card-title>
                <v-icon size="28" class="pageicon">list</v-icon> Announcement List
                
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-row>
                    
                    <v-col cols="12" sm="8" class="text-right">
                        
                        <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                            
                    </v-col>
                    <v-col cols="12" sm="2" class="text-left">
                        <v-btn small color="warning" id="search-btn" class="ma-2 white--text" @click="getList" >
                                    Search
                                    <v-icon right dark >search</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
                
                
                </v-card-title>

                <v-data-table
                    :page="page"
                    :pageCount="numberOfPages"
                    :headers="computedHeaders"
                    :items="list"
                    :options.sync="options"
                    :server-items-length="totalItem"
                    :loading="listLoading"
                    :footer-props="footerProps"
                    
                >
                
                    
                    <template v-slot:[`item.setting`]="{ item }">
                        <v-btn small color="success" id="btn-list" 
                            class="ma-2 white--text btn-list" 
                            @click="goToAnnouncementEdit(item.id)" 
                            >
                        Edit
                        <v-icon right dark >edit</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: 'List-Announcement',
  components: {  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
        search: '',
        footerProps: {
            'items-per-page-options': [50, 100, 300, 1000, 9999]
        },
        headers: [
          
          { text: 'Title', value: 'title' , sortable: true},
          { text: 'Category', value: 'category' , sortable: true},
          { text: 'Posted Time', value: 'postedOn' , sortable: true},
          { text: 'Active', value: 'active' , sortable: true},
          { text: 'Setting', align: 'center', value: 'setting'},
        ],
      
      list: [],
      total: 0,
      listLoading: true,

      page: 1,
      totalItem: 0,
      numberOfPages: 0,
      options: {},

    }
  },
  watch: {
    search: {
        handler(newVal, oldVal) {
            // do something with the object
            sessionStorage.setItem("pp-announcement-listing-settings", JSON.stringify(this.search));
        },
        deep: true,
    },
    options: {
      handler() {
        this.getList();
      },
    },
    deep: true,
  },
  created() {
    /* Check if got memory remember */
    if(sessionStorage.getItem("pp-announcement-listing-settings") !== null){
        var vm = this;

        vm.search = JSON.parse(sessionStorage.getItem("pp-announcement-listing-settings"));
    }
    
  },
  computed: {
   computedHeaders () {
      return this.headers;
   }
  },
  mounted() {
    //this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true
      var vm = this;

      // if(this.options.sortBy[0] === 'timestamp'){
      //   this.options.sortBy[0] = 'postedOn';
      // }

      const { page, itemsPerPage, sortBy, sortDesc, groupBy, groupDesc, multiSort } = this.options;
      let pageNumber = page - 1;

      const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
        };

      let loader = this.$loading.show({
                    // Optional parameters
                    });

      
      let url = process.env.VUE_APP_MICROSERVICE_URL+'/announcement/list?&page='+ pageNumber + '&size='+itemsPerPage+'&sort='+ sortBy +'&desc=' +sortDesc+'&search='+vm.search;
      
      this.$http.get(url,config)
        .then(response => {
            vm.list = response.data.list
            vm.list.map(item => {
              item.postedOn = moment(item.postedOn).format('DD/MM/YYYY hh:mm:ss a');
            })
            vm.totalItem = response.data.totalItem;
            vm.numberOfPages = response.data.totalPage;
            vm.listLoading = false;
            loader.hide();
        })
        .catch(function (error) {
            vm.listLoading = false;
            loader.hide();
            console.error(error);
        });

    },
    goToAnnouncementEdit(id){
        var vm = this;
        let data = vm.list.filter(item => 
            item.id == id
        );
        this.$router.push({
            path: "edit-announcement/"+id,
            params: { data }
        });
    }
  }
}
</script>

<style scoped>
.container{
    margin-top: 20px;
}
#btn-list {
  margin-left: 0px !important;
}
#search-btn {
    margin-left: 0px !important;
    margin-bottom: -30px ! important;
}
.pageicon{
  margin-right: 20px;
}
</style>
