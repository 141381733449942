var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"main-header",attrs:{"height":"64","fixed":"","color":"primary","dark":""}},[_c('v-btn',{staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.TOGGLE_DRAWER($event)}}},[(_vm.DRAWER_STATE)?[_c('v-icon',{staticStyle:{"font-size":"28px"}},[_vm._v("mdi-menu")])]:[_c('v-icon',{staticStyle:{"font-size":"28px"}},[_vm._v("mdi-menu")])]],2),_c('v-toolbar-title',[_vm._v("NKL Portal")]),_c('v-toolbar-title',[_c('v-img',{attrs:{"src":require("@/assets/nkl-logo.png"),"contain":"","max-height":"50","max-width":"300"}})],1),_c('v-spacer'),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.xs),expression:"!$vuetify.breakpoint.xs"}],attrs:{"min-width":"80","offset-y":"","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.xs),expression:"!$vuetify.breakpoint.xs"}],staticStyle:{"margin-right":"30px"},domProps:{"textContent":_vm._s(_vm.username)}},on))]}}])}),(_vm.compavatar != null)?_c('v-menu',{attrs:{"min-width":"80","offset-y":"","bottom":"","right":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",staticStyle:{"font-size":"12px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',[_c('img',{staticClass:"passportPreview",attrs:{"src":_vm.compavatar,"alt":""}})])],1)]}}],null,false,3056863664)},[_c('v-list',[_c('div',{staticClass:"text-h9 grey--text text--darken-3 px-4"},[_vm._v(_vm._s(_vm.name))]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.account),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.$router.push({ path: item.route })}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"color":_vm.config.light.textColor},domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1),_c('div',{staticClass:"d-flex justify-center my-3"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"width":"80%","large":"","outlined":"","color":"primary"},on:{"click":_vm.logOut}},[_vm._v("Sign Out ")])],1)],1)],1):_vm._e(),(_vm.compavatar == null)?_c('v-menu',{attrs:{"min-width":"80","offset-y":"","bottom":"","right":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",staticStyle:{"font-size":"12px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"28px"},attrs:{"color":"rgba(255, 255, 255, 1)"}},[_vm._v("mdi-account")])],1)]}}],null,false,100294022)},[_c('v-list',[_c('div',{staticClass:"text-h9 grey--text text--darken-3 px-4"},[_vm._v(_vm._s(_vm.name))]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.account),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.$router.push({ path: item.route })}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"color":_vm.config.light.textColor},domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1),_c('div',{staticClass:"d-flex justify-center my-3"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"width":"80%","large":"","outlined":"","color":"primary"},on:{"click":_vm.logOut}},[_vm._v("Sign Out ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }