<template>
   <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
              <v-card class="dashboardcard">
                <v-card-title> <v-icon size="28" class="pageicon">menu_book</v-icon>Examination</v-card-title>
                
                <div v-if="results.length == 0">
                    <v-card class="pair-card" elevation="5" >
                        <v-toolbar dense color="light-blue darken-4" dark >
                            <v-btn icon>
                            <v-icon>bookmarks</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                        <v-row class="row-card">
                            <v-col cols="12" sm="12" xs="12" >
                            There is no news/posts from admin. Do come back and check it later.
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
                
                <div v-for="(il, index) in intakes" :key="index">
                    <v-card class="pair-card" elevation="5" >
                        <v-toolbar dense color="green darken-4" dark >
                            <v-btn icon>
                            <v-icon>auto_stories</v-icon>
                            </v-btn>
                            <v-toolbar-title>Intake: {{il.name}}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                    
                        <!-- For MQA -->
                        <div v-if="il.semesters.length > 0">
                            <div v-for="(sl, s) in il.semesters" :key="s">
                                <v-card class="semestercard" elevation="24">
                                    <v-row class="row-card">
                                        <v-col cols="12" sm="3" md="3" >
                                            
                                            <v-chip class="ma-2" color="cyan darken-4" label 
                                            text-color="white">
                                            <v-icon left>
                                                history_edu
                                            </v-icon>
                                            Semester: {{sl.semester}}
                                            </v-chip>
                                            <!-- <v-text-field
                                                outlined
                                                :value="`Semester ${sl.semester}`"
                                                readonly
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="12" sm="6" md="6"  >
                                                
                                                <div v-if="sl.transcripts.length == 0">
                                                    N/A
                                                </div>
                                                
                                                <div v-for="(al, a) in sl.transcripts" :key="a">
                                                    
                                                            <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                                                <template v-slot:activator="{ on, attrs }">  
                                                                    <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                                        
                                                                    v-bind="attrs" v-on="on"
                                                                        @click="al.dialogAction = true">
                                                                            <span style="white-space: normal;">
                                                                            {{ al.name }}
                                                                            </span>
                                                                            <v-icon right dark >info</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-card>
                                                                    <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                                                    <v-toolbar color="indigo" dark dense>
                                                                        <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                                                    </v-toolbar>
                                                                    <v-divider></v-divider>
                                                                    <v-card-text style="height: 800px;">
                                                                        <v-container>
                                                                            <v-row>
                                                                                <v-col cols="12" sm="12" md="12" >
                                                                                    <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                        @click="$refs['myPdfComponent'+index+''+s+''+ a][0].print()">
                                                                                        print
                                                                                        <v-icon right dark >print</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                        @click="downloadPDF(al.data,al.name)">
                                                                                        Download
                                                                                        <v-icon right dark >file_download</v-icon>
                                                                                    </v-btn>
                                                                                    
                                                                                    <pdf 
                                                                                        :ref="`myPdfComponent${index}${s}${a}`"
                                                                                        v-for="i in al.numPages"
                                                                                        :key="i"
                                                                                        :page="i" 
                                                                                        :src="al.data">
                                                                                    </pdf>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-container>
                                                                    </v-card-text>
                                                                    <v-divider></v-divider>
                                                                    <v-card-actions>
                                                                    <v-btn
                                                                        color="blue darken-1"
                                                                        text
                                                                        @click="al.dialogAction = false"
                                                                    >
                                                                        Close
                                                                    </v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                        
                                                </div>                
                                                
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </div>
                        </div>


                        <!-- For Other -->
                        <div v-if="il.semesters.length == 0">
                            
                                <v-card class="semestercard" elevation="24">
                                    <v-row class="row-card">
                                        

                                        <v-col cols="12" sm="6" md="6"  >
                                                
                                                <div v-if="il.transcripts == undefined">
                                                    N/A
                                                </div>
                                                
                                                <div v-for="(al, a) in il.transcripts" :key="a">
                                                    
                                                            <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                                                <template v-slot:activator="{ on, attrs }">  
                                                                    <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                                        
                                                                    v-bind="attrs" v-on="on"
                                                                        @click="al.dialogAction = true">
                                                                            <span style="white-space: normal;">
                                                                            {{ al.name }}
                                                                            </span>
                                                                            <v-icon right dark >info</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-card>
                                                                    <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                                                    <v-toolbar color="indigo" dark dense>
                                                                        <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                                                    </v-toolbar>
                                                                    <v-divider></v-divider>
                                                                    <v-card-text style="height: 800px;">
                                                                        <v-container>
                                                                            <v-row>
                                                                                <v-col cols="12" sm="12" md="12" >
                                                                                    <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                        @click="$refs['myPdfComponent'+index+''+a][0].print()">
                                                                                        print
                                                                                        <v-icon right dark >print</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                        @click="downloadPDF(al.data,al.name)">
                                                                                        Download
                                                                                        <v-icon right dark >file_download</v-icon>
                                                                                    </v-btn>
                                                                                    <pdf 
                                                                                        :ref="`myPdfComponent${index}${a}`"
                                                                                        v-for="i in al.numPages"
                                                                                        :key="i"
                                                                                        :page="i" 
                                                                                        :src="al.data">
                                                                                    </pdf>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-container>
                                                                    </v-card-text>
                                                                    <v-divider></v-divider>
                                                                    <v-card-actions>
                                                                    <v-btn
                                                                        color="blue darken-1"
                                                                        text
                                                                        @click="al.dialogAction = false"
                                                                    >
                                                                        Close
                                                                    </v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                        
                                                </div>                
                                                
                                        </v-col>
                                    </v-row>
                                </v-card>
                            
                        </div>
                    </v-card>
                </div>
                
              </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import pdf from 'vue-pdf';

export default {
  name: "StudentTranscriptMain",
  components: {
    pdf
  },
  data() {
    return {
      total: 0,
      listLoading: true,
      editorOption: {
          readOnly: true,
          theme: 'snow',
          modules: {
              toolbar : false
          }
      },
      disabled: true,
      results: [],
      intakes: []
    };
  },
  created() {
    
        this.getTranscript();
  },
  methods: {
    downloadPDF(e,filename){
            
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        //var blob = new Blob([e], { type: "application/pdf" }),
        var blob = this.dataURItoBlob(e);
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    },
    dataURItoBlob(dataURI){
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
    },
    getTranscript(){
        this.listLoading = true
        let loader = this.$loading.show({
                // Optional parameters
               
                });
        
        var vm = this;
        const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

        this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/transcript/get/student/transcript?studentId='+localStorage.getItem('id'),config)
            .then(response => {
                
                vm.results = response.data.transcripts;
                vm.intakes = response.data.intakes;

                vm.results.map(item => {
                    //Add dialogAction for PDF open
                    vm.$set(item, 'dialogAction', false);
                    
                })

                vm.intakes.map(item => {
                    
                    let result = vm.results.filter(re => re.intakeId === item.id);

                    if(result.length >0){
                        //If MQA
                        if(item.semesters.length>0 ){

                            item.semesters.map(b => {
                                let transcripts = [];
                                result.map(a => {
                                    
                                    if(b.semester === a.semester){
                                        transcripts.push(a);
                                    }
                                
                                })
                                vm.$set(b, 'transcripts', transcripts);
                            })
                            
                        }else{
                            vm.$set(item, 'transcripts', result);
                        }
                    }
                    

                })



                vm.listLoading = false;
                loader.hide();
                
            })
            .catch(function (error) {
                vm.listLoading = false
                console.error(error);
                loader.hide();
            });
    },
  }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
    padding-bottom: 20px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
.v-toolbar__title {
    font-size: 1rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dashboardcard{
  padding-bottom: 30px;
}
.pdfExpand{
    border: 1px solid #0c0c0c;
    margin-top: 20px;
    margin-bottom: 20px;
}
.divPdfExpand{
    margin-top: 20px;
}
.semestercard{
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
</style>
