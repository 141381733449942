<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
            <v-card class="main-card">
              <v-card-title> <v-icon size="28" class="pageicon">accessibility_new</v-icon> User registration</v-card-title>
                  <v-row>
                      <v-col cols="12" sm="12" class="text-right" >
                          <v-btn color="success" class="ma-5 white--text" @click="addNewUser" >
                          Add User
                          <v-icon right dark >save</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
                  <v-row class="row-card-pass" >
                      <v-col cols="12" sm="12" >
                          <v-label v-if="tempPass != null">Your temporary generated password: {{tempPass}}</v-label>
                      </v-col>
                  </v-row>
                  <v-row class="row-card">
                      <!-- <v-col cols="12" sm="4" >
                          <v-text-field
                              label="Name"
                              v-model="user.name"
                              required
                              @output="$v.user.name.$touch()"
                              @blur="$v.user.name.$touch()"
                              :error-messages="!$v.user.name.required ? ['Name is required'] : ''"
                          ></v-text-field>
                      </v-col> -->
                      <v-col cols="12" sm="4" >
                          <v-text-field
                              label="Login Username"
                              v-model="user.username"
                              hint="Recommend to put student ID for student"
                              persistent-hint
                              @output="$v.user.username.$touch()"
                              @blur="$v.user.username.$touch()"
                              :error-messages="!$v.user.username.required ? ['Login username is required'] :
                                              usernameTaken ? ['User name is taken.'] : ''
                                              "
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" >
                          <v-text-field
                              v-model="user.email"
                              :rules="emailRules"
                              label="Email address"
                              @input="$v.user.email.$touch()"
                              @blur="$v.user.email.$touch()"
                              :error-messages="!$v.user.email.required ? ['Email address is required'] :
                                        emailTaken ? ['Email address is taken.'] : ''
                                              "
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" >
                        <v-select
                                v-model="user.role"
                                :items="roles"
                                item-text="name"
                                item-value="value"
                                attach
                                persistent-hint
                                label="Roles"
                                :error-messages="!$v.user.role.required ? ['Role is required'] : ''"
                            ></v-select>
                      </v-col>
                  </v-row>
                  <v-row class="row-card">
                      <v-col cols="12" sm="4" v-if="user.role !== 'ROLE_STUDENT'">
                            <v-text-field
                              label="Name"
                              v-model="user.name"
                              required
                              @input="user.name = user.name.toUpperCase()"
                              @output="$v.user.name.$touch()"
                              @blur="$v.user.name.$touch()"
                              :error-messages="!$v.user.name.required ? ['Name is required'] : ''"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" >
                          <v-select
                            v-model="user.intakeIds"
                            :items="intakesList"
                            item-text="name"
                            item-value="id"
                            attach
                            chips
                            persistent-hint
                            label="Intake"
                            multiple
                        ></v-select>
                      </v-col>
                  </v-row>
                    <v-row class="row-card" v-if="user.role !== 'ROLE_STUDENT'">
                        <v-col cols="12" sm="8" md="8" v-if="user.passport.data != null && user.passport.data != ''">
                            <v-card-text style="height: 200px;">
                                <v-img
                                    :src="user.passport.data"
                                    max-height="200"
                                    max-width="250"
                                ></v-img>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row class="row-card" v-if="user.role !== 'ROLE_STUDENT'">
                        
                        <v-col cols="12" sm="4" >
                            <v-file-input
                            v-model="fileupload"
                            accept="image/png, image/jpeg, image/bmp"
                            prepend-icon="mdi-camera"
                            label="Upload Avatar Photo"
                            @change="addImage($event)"
                            :error-messages="!fileSizeValid ? ['Maximum file size is 6mb'] : ''"
                            ></v-file-input>
                            
                        </v-col>
                        
                    </v-row>

                <!-- Personal details -->
                <v-card class="pair-card" elevation="11" v-if="user.role === 'ROLE_STUDENT'">
                    <v-toolbar dense color="teal darken-1" dark >
                        <v-btn icon>
                            <v-icon>info</v-icon>
                        </v-btn>
                        <v-toolbar-title>Personal Details</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-title >  </v-card-title>
                    <v-row class="row-card">
                        <v-col cols="12" sm="8" md="8" v-if="user.passport.data != null && user.passport.data != ''">
                            <v-card-text style="height: 200px;">
                                <img class="passportPreview" :src="user.passport.data" alt="" >
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        
                        <v-col cols="12" sm="4" >
                            <v-file-input
                            v-model="fileupload"
                            accept="image/png, image/jpeg, image/bmp"
                            prepend-icon="mdi-camera"
                            label="Upload Passport size photo"
                            @change="addImage($event)"
                            ></v-file-input>
                            
                            <!-- <v-dialog v-model="user.passport.dialogAction" scrollable max-width="600px" v-if="user.passport.data != null && user.passport.data != ''">
                                <template v-slot:activator="{ on, attrs }">  
                                    <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                        
                                    v-bind="attrs" v-on="on"
                                        @click="user.passport.dialogAction = true">
                                            <span style="white-space: normal;">
                                            Image Preview
                                            </span>
                                            <v-icon right dark >info</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                   <v-toolbar color="indigo" dark >
                                        <v-toolbar-title>Image Preview</v-toolbar-title>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text style="height: 400px;">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12" >
                                                    <img class="imagePreview" :src="user.passport.data" alt="" >
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="user.passport.dialogAction = false"
                                    >
                                        Close
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog> -->
                        </v-col>
                        
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-select
                                v-model="user.salutation"
                                :items="salutations"
                                attach
                                persistent-hint
                                label="Salutation"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="user.salutation === 'Other'">
                            <v-text-field
                              label="Other salutation"
                              v-model="user.salutationOther"
                              @input="user.salutationOther = user.salutationOther.toUpperCase()"
                              @output="$v.user.salutationOther.$touch()"
                              @blur="$v.user.salutationOther.$touch()"
                              :error-messages="!$v.user.salutationOther.required ? ['Other salutation is required'] : ''
                                              "
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-text-field
                              label="Name (As in NRIC/Passport)"
                              v-model="user.name"
                              required
                              @input="user.name = user.name.toUpperCase()"
                              @output="$v.user.name.$touch()"
                              @blur="$v.user.name.$touch()"
                              :error-messages="!$v.user.name.required ? ['Name is required'] : ''"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <v-text-field
                              :rules="nricRules"
                              label="NRIC"
                              v-model="user.nric"
                              persistent-hint
                              hint="example: 880808-14-8888"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <v-select
                                v-model="user.nationality"
                                :items="nationalityList"
                                item-text="name"
                                item-value="name"
                                attach
                                persistent-hint
                                label="Nationality"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-card class="pair-card-sub sub-card" elevation="5" >
                        <v-toolbar dense color="teal lighten-1" dark >
                            <v-toolbar-title>For International Student Only</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Passport No."
                                v-model="user.passportNo"
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-menu
                                    ref="menupassportexpiry"
                                    v-model="menupassportexpiry"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="user.passportExpiry"
                                        label="Passport Expiry"
                                        readonly
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="user.passportExpiry"
                                        @input="menupassportexpiry = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-menu
                                ref="menudob"
                                v-model="menudob"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="user.dob"
                                    label="Date of birth"
                                    readonly
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="user.dob"
                                    @input="menudob = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <v-text-field
                              label="Age"
                              v-model="user.age"
                              type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <v-select
                                v-model="user.gender"
                                :items="genders"
                                attach
                                label="Gender"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-select
                                v-model="user.race"
                                :items="races"
                                attach
                                persistent-hint
                                label="Race"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="user.race === 'Other'">
                            <v-text-field
                              label="Other race"
                              v-model="user.raceOther"
                              @input="user.raceOther = user.raceOther.toUpperCase()"
                              @output="$v.user.raceOther.$touch()"
                              @blur="$v.user.raceOther.$touch()"
                              :error-messages="!$v.user.raceOther.required ? ['Other race is required'] : ''
                                              "
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-select
                                v-model="user.religion"
                                :items="religions"
                                attach
                                persistent-hint
                                label="Religion"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="user.religion === 'Other'">
                            <v-text-field
                              label="Other religion"
                              v-model="user.religionOther"
                              @input="user.religionOther = user.religionOther.toUpperCase()"
                              @output="$v.user.religionOther.$touch()"
                              @blur="$v.user.religionOther.$touch()"
                              :error-messages="!$v.user.religionOther.required ? ['Other religion is required'] : ''
                                              "
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="8" >
                            <v-textarea
                                outlined
                                label="Address"
                                v-model="user.address"
                                >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-text-field
                              label="Postcode"
                              v-model="user.postCode"
                              type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <v-select
                                v-model="user.state"
                                :items="states"
                                attach
                                persistent-hint
                                label="State"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <VuePhoneNumberInput @update="getResiContactNo($event)" 
                                :translations="ResiTranslations"
                                default-country-code="MY" 
                                :size="breakPoint" 
                                v-model="user.residentialPhone"
                                error-color="red" color="red"
                                :only-countries="countriesList"
                                
                                />
                            <div v-if="user.contactResicheck" style="margin-top:8px" class="v-text-field__details">
                                <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                        <div class="v-messages__message">Invalid Contact No.</div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <VuePhoneNumberInput @update="getContactNo($event)" 
                                :translations="phoneTranslations"
                                default-country-code="MY" 
                                :size="breakPoint"
                                v-model="user.phone"
                                error-color="red" color="red"
                                :only-countries="countriesList"
                                />
                            <div v-if="user.contactcheck" style="margin-top:8px" class="v-text-field__details">
                                <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                        <div class="v-messages__message">Invalid Contact No.</div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
                

                <!-- Parents/Guardian details -->
                <v-card class="pair-card" elevation="11" v-if="user.role === 'ROLE_STUDENT'">
                    <v-toolbar dense color="indigo darken-1" dark >
                        <v-btn icon>
                            <v-icon>family_restroom</v-icon>
                        </v-btn>
                        <v-toolbar-title>Parents/Guardian Details</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-title >  </v-card-title>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-text-field
                              label="Name"
                              v-model="user.pgName"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                              label="Relationship"
                              v-model="user.pgRelationship"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="8" >
                            <v-textarea
                                outlined
                                label="Address"
                                v-model="user.pgAddress"
                                >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <VuePhoneNumberInput @update="getPGResiContactNo($event)" 
                                :translations="ResiTranslations"
                                default-country-code="MY" 
                                :size="breakPoint" 
                                v-model="user.pgResidentialPhone"
                                error-color="red" color="red"
                                :only-countries="countriesList"
                                
                                />
                            <div v-if="user.pgcontactResicheck" style="margin-top:8px" class="v-text-field__details">
                                <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                        <div class="v-messages__message">Invalid Contact No.</div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <VuePhoneNumberInput @update="getPGContactNo($event)" 
                                :translations="phoneTranslations"
                                default-country-code="MY" 
                                :size="breakPoint"
                                v-model="user.pgPhone"
                                error-color="red" color="red"
                                :only-countries="countriesList"
                                />
                            <div v-if="user.pgcontactcheck" style="margin-top:8px" class="v-text-field__details">
                                <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                        <div class="v-messages__message">Invalid Contact No.</div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-text-field
                                v-model="user.pgEmail"
                                :rules="emailRules"
                                label="Email address"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
                
                <!-- Emergency details-->
                <v-card class="pair-card" elevation="11" v-if="user.role === 'ROLE_STUDENT'">
                    <v-toolbar dense color="orange darken-3" dark >
                        <v-btn icon>
                            <v-icon>report</v-icon>
                        </v-btn>
                        <v-toolbar-title>Emergency Details</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-title >  </v-card-title>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-text-field
                              label="Name"
                              v-model="user.eName"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                              label="Relationship"
                              v-model="user.eRelationship"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="8" >
                            <v-textarea
                                outlined
                                label="Address"
                                v-model="user.eAddress"
                                >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <VuePhoneNumberInput @update="getEResiContactNo($event)" 
                                :translations="ResiTranslations"
                                default-country-code="MY" 
                                :size="breakPoint" 
                                v-model="user.eResidentialPhone"
                                error-color="red" color="red"
                                :only-countries="countriesList"
                                
                                />
                            <div v-if="user.econtactResicheck" style="margin-top:8px" class="v-text-field__details">
                                <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                        <div class="v-messages__message">Invalid Contact No.</div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <VuePhoneNumberInput @update="getEContactNo($event)" 
                                :translations="phoneTranslations"
                                default-country-code="MY" 
                                :size="breakPoint"
                                v-model="user.ePhone"
                                error-color="red" color="red"
                                :only-countries="countriesList"
                                />
                            <div v-if="user.econtactcheck" style="margin-top:8px" class="v-text-field__details">
                                <div class="v-messages theme--light error--text" role="alert">
                                    <div class="v-messages__wrapper">
                                        <div class="v-messages__message">Invalid Contact No.</div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" >
                            <v-text-field
                                v-model="user.eEmail"
                                :rules="emailRules"
                                label="Email address"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                 </v-card>

                <!-- Academic Background-->
                <v-card class="pair-card" elevation="11" v-if="user.role === 'ROLE_STUDENT'">
                    <v-toolbar dense color="green darken-3" dark >
                        <v-btn icon>
                            <v-icon>auto_stories</v-icon>
                        </v-btn>
                        <v-toolbar-title>Academic Background</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-title >  </v-card-title>

                    <!-- SPM / O Level-->
                    <v-card class="pair-card-sub sub-card" elevation="5" >
                        <v-toolbar dense color="light-green darken-3" dark >
                            <v-toolbar-title>SPM/O-Level</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Name of school"
                                v-model="user.spmOlevel.schoolName"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Bahasa Melayu - Grade"
                                v-model="user.spmOlevel.bmGrade"
                                outlined
                                hint="Example: A+, C+, E"
                                persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="English - Grade"
                                v-model="user.spmOlevel.enGrade"
                                outlined
                                hint="Example: A+, C+, E"
                                persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Sejarah - Grade"
                                v-model="user.spmOlevel.sejGrade"
                                outlined
                                hint="Example: A+, C+, E"
                                persistent-hint
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Modern Mathematics - Grade"
                                v-model="user.spmOlevel.mathGrade"
                                outlined
                                hint="Example: A+, C+, E"
                                persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Number of credits"
                                v-model="user.spmOlevel.numOfCredits"
                                outlined
                                type="number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <!-- IGCSE-->
                    <v-card class="pair-card-sub sub-card" elevation="5" >
                        <v-toolbar dense color="lime darken-3" dark >
                            <v-toolbar-title>IGCSE</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Name of school"
                                v-model="user.igcse.schoolName"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="English - Grade"
                                v-model="user.igcse.enGrade"
                                outlined
                                hint="Example: A+, C+, E"
                                persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Modern Mathematics - Grade"
                                v-model="user.igcse.mathGrade"
                                outlined
                                hint="Example: A+, C+, E"
                                persistent-hint
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Number of credits"
                                v-model="user.igcse.numOfCredits"
                                outlined
                                type="number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- STPM/ A Level-->
                    <v-card class="pair-card-sub sub-card" elevation="5" >
                        <v-toolbar dense color="cyan darken-3" dark >
                            <v-toolbar-title>STPM/A-Level</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Name of school"
                                v-model="user.stpmALevel.schoolName"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-select
                                    v-model="user.stpmALevel.stream"
                                    :items="streams"
                                    attach
                                    label="Stream"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4" v-if ="user.stpmALevel.stream == 'Other'">
                                <v-text-field
                                label="Others"
                                v-model="user.stpmALevel.streamOther"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="CGPA"
                                v-model="user.stpmALevel.cgpa"
                                outlined
                                type="number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- UEC-->
                    <v-card class="pair-card-sub sub-card" elevation="5" >
                        <v-toolbar dense color="light-blue darken-4" dark >
                            <v-toolbar-title>UEC</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Name of school"
                                v-model="user.uec.schoolName"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="English - Grade"
                                v-model="user.uec.enGrade"
                                outlined
                                hint="Example: A+, C+, E"
                                persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Modern Mathematics - Grade"
                                v-model="user.uec.mathGrade"
                                outlined
                                hint="Example: A+, C+, E"
                                persistent-hint
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                label="Number of credits"
                                v-model="user.uec.numOfCredits"
                                outlined
                                type="number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                 </v-card>
            </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import 'vuejs-noty/dist/vuejs-noty.css'
import Noty from 'noty';
import { validationMixin } from 'vuelidate';
import { required, requiredUnless } from 'vuelidate/lib/validators';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  mixins: [validationMixin],
  name: "Dashboard",
  components: { VuePhoneNumberInput },
  validations: {
    user:{
        name: { required },
        email : { required },
        role: { required },
        username: { required },

        salutationOther: { 
            required : requiredUnless(function() {
                         return this.user.salutation != 'Other'
                     })
        },
        raceOther: { 
            required : requiredUnless(function() {
                         return this.user.race != 'Other'
                     })
        },
        religionOther: { 
            required : requiredUnless(function() {
                         return this.user.religion != 'Other'
                     })
        },
    }
  },
  computed: {
    breakPoint () {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': 
                return 'sm'
            case 'sm': 
                return 'sm'
            case 'md': 
                return 'sm'
            case 'lg': 
                return 'lg'
            case 'xl': 
                return 'lg'
            default:
                return 'lg'
        }
    }
  },
  data() {
    return {
        fileSizeValid: true,
        fileupload: null,
        menupassportexpiry: false,
        menudob: false,

        streams:['Science','Account','Art','Other'],
        states: ['Johor','Kedah','Kelantan','Malacca','Negeri Sembilan','Pahang','Penang','Perak','Perlis','Sabah','Sarawak','Selangor','Terengganu','Kuala Lumpur','Labuan','Putrajaya'],
        religions: ['Muslim','Buddhist','Hindu','Christian','Other'],
        races: ['Malay','Chinese','Indian','Other'],
        salutations: ['Mr','Mrs','Miss','Other'],
        genders: ['Male','Female'],
        roles: [
            { name : 'Student', value : 'ROLE_STUDENT'},
            { name : 'Teacher', value : 'ROLE_TEACHER'},
            { name : 'Staff', value : 'ROLE_STAFF'}
        ],
        emailRules: [
            v => !v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
        ],
        nricRules: [
            v => !v || /^\d{6}-\d{2}-\d{4}$/.test(v) || 'NRIC must be valid',
        ],
        countriesList: ['MY'],
        intakesList: [],
        nationalityList: [],
        tempPass: null,
        usernameTaken: false,
        emailTaken: false,

        ResiTranslations: {
            countrySelectorLabel: 'Country',
            countrySelectorError: 'Country error',
            phoneNumberLabel: 'Residential Phone Number',
            example: 'Example :'
        },

        phoneTranslations: {
            countrySelectorLabel: 'Country',
            countrySelectorError: 'Country error',
            phoneNumberLabel: 'Mobile Phone Number',
            example: 'Example :'
        },

        user:{

                passport:{
                    data: null,
                    name: null,
                    dialogAction: false
                },
                name: null,
                username: null,
                role: null,
                
                
                contactcheck: false,
                contactResicheck: false,
                intakeIds: [],

                salutation: null,
                salutationOther: null,
                nric: null,
                nationality: null,
                passportNo: null,
                passportExpiry: null,
                dob: null,
                gender: null,
                race:null,
                raceOther: null,
                religion: null,
                religionOther: null,
                address: null,
                postCode: null,
                state: null,
                phone: null,
                residentialPhone: null,
                email: null,

                pgName: null,
                pgRelationship: null,
                pgAddress: null,
                pgResidentialPhone: null,
                pgPhone: null,
                pgEmail: null,
                pgcontactcheck: false,
                pgcontactResicheck: false,

                eName: null,
                eRelationship: null,
                eAddress: null,
                eResidentialPhone: null,
                ePhone: null,
                eEmail: null,
                econtactcheck: false,
                econtactResicheck: false,

                spmOlevel:{
                    schoolName: null,
                    bmGrade: null,
                    enGrade: null,
                    sejGrade: null,
                    mathGrade: null,
                    numOfCredits: null
                },

                igcse:{
                    schoolName: null,
                    enGrade: null,
                    mathGrade: null,
                    numOfCredits: null
                },

                stpmALevel:{
                    schoolName: null,
                    stream: null,
                    streamOther: null,
                    cgpa: null
                },

                uec:{
                    schoolName: null,
                    enGrade: null,
                    mathGrade: null,
                    numOfCredits: null
                }
                
        }
    };
  },
  created(){
        this.getIntakes();
        if(sessionStorage.getItem("pp-nationality") !== null){
            var vm = this;
            vm.nationalityList = JSON.parse(sessionStorage.getItem("pp-nationality"));
        }else{
            this.getNationality();
        }
        
  },
  methods: {
    addImage(e){
        var vm = this;
        if(e !== null){
            if(e.size > 6291456){
                vm.fileSizeValid = false;
                vm.fileupload = null;
            }else{
                const selectedImage = e;
                const reader = new FileReader();
                reader.onload= (f)=> {
                    vm.user.passport.data = f.target.result;
                    vm.fileupload = null;
                };
                reader.readAsDataURL(selectedImage);
                vm.user.passport.name = e.name;
                // vm.identity.identityUDFs[index].file.fileSize = e.size;
                // vm.identity.identityUDFs[index].file.fileType = e.type;
            }
        }
    },
    getNationality(){
        this.listLoading = true
        let loader = this.$loading.show({
                // Optional parameters
                
                });
        
        var vm = this;
        const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

        this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/ref/get/all/nationality',config)
            .then(response => {
                
                vm.nationalityList = response.data.nationalities;
                sessionStorage.setItem("pp-nationality", JSON.stringify(vm.nationalityList));

                vm.listLoading = false;
                loader.hide();
                
            })
            .catch(function (error) {
                vm.listLoading = false
                console.error(error);
                loader.hide();
            });
    },
    getIntakes(){
            this.listLoading = true
            let loader = this.$loading.show({
                    // Optional parameters
                    });
            
            var vm = this;
            const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

            this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/active',config)
                .then(response => {
                    
                    // vm.intakesList = [];
                    // let intakes = response.data.intakes
                    //vm.intakesList.push.apply(vm.intakesList,intakes.map(item => {return { intakeId: item.id, name: item.name}}));
        
                    vm.intakesList = response.data.intakes;

                    vm.listLoading = false;
                    loader.hide();
                    
                })
                .catch(function (error) {
                    vm.listLoading = false
                    console.error(error);
                    loader.hide();
                });
    },
    addNewUser(){
        var vm = this;
        if(vm.user.role != 'ROLE_STUDENT'){
            // vm.user.passport.data = null;
            // vm.user.passport.name = null;
            vm.user.salutation = null;
            vm.user.salutationOther;
            vm.user.nric= null;
            vm.user.nationality= null;
            vm.user.passportNo= null;
            vm.user.passportExpiry= null;
            vm.user.dob= null;
            vm.user.gender= null;
            vm.user.race=null;
            vm.user.raceOther= null;
            vm.user.religion= null;
            vm.user.religionOther= null;
            vm.user.address= null;
            vm.user.postCode= null;
            vm.user.state= null;
            vm.user.phone= null;
            vm.user.residentialPhone= null;

            vm.user.pgName= null;
            vm.user.pgRelationship= null;
            vm.user.pgAddress= null;
            vm.user.pgResidentialPhone= null;
            vm.user.pgPhone= null;
            vm.user.pgEmail= null;
            vm.user.eName= null;
            vm.user.eRelationship= null;
            vm.user.eAddress= null;
            vm.user.eResidentialPhone= null;
            vm.user.ePhone= null;
            vm.user.eEmail= null;

            vm.user.spmOlevel.schoolName= null;
            vm.user.spmOlevel.bmGrade= null;
            vm.user.spmOlevel.enGrade= null;
            vm.user.spmOlevel.sejGrade= null;
            vm.user.spmOlevel.mathGrade= null;
            vm.user.spmOlevel.numOfCredits= null;

            vm.user.igcse.schoolName= null;
            vm.user.igcse.enGrade= null;
            vm.user.igcse.mathGrade= null;
            vm.user.igcse.numOfCredits= null;

            vm.user.stpmALevel.schoolName= null;
            vm.user.stpmALevel.stream= null;
            vm.user.stpmALevel.streamOther= null;
            vm.user.stpmALevel.cgpa= null;

            vm.user.uec.schoolName= null;
            vm.user.uec.enGrade= null;
            vm.user.uec.mathGrade= null;
            vm.user.uec.numOfCredits= null;
        }

        let userDefinedCheck = false;
        var emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!emailRE.test(this.user.email)){
            userDefinedCheck = true;
        }

        if(this.user.pgEmail != null && this.user.pgEmail != '' && !emailRE.test(this.user.pgEmail)){
            userDefinedCheck = true;
        }

        var nricRE = /^\d{6}-\d{2}-\d{4}$/;
        if(this.user.nric != null && this.user.nric != '' && !nricRE.test(this.user.nric)){
            userDefinedCheck = true;
        }
        
        if(this.user.contactcheck || this.user.contactResicheck 
            || this.user.pgcontactcheck || this.user.pgcontactResicheck
            || this.user.econtactcheck || this.user.econtactResicheck){
            userDefinedCheck = true;
        }
        
        if(this.$v.$invalid  || userDefinedCheck ){
            this.$v.$touch();
        }
        else{

            this.listLoading = true
            let loader = this.$loading.show({
                    // Optional parameters
                    
                    });

            
            const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

            const bodyParameters = {
                key: "value"
            };

            this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/identity/register?userName='+localStorage.getItem('username'),this.user,config)
            .then(response => {

                
                loader.hide();
                vm.usernameTaken = false;
                vm.emailTaken = false;

                //notification of successfully saved
                var notification = new Noty({
                    killer: false,
                    timeout: 5000,
                    type: 'success',
                    text: vm.roles.filter(item => item.value == vm.user.role)[0].name+': '+vm.user.name+' is created.',
                    theme: 'metroui',
                    layout: 'topRight'
                })
                var notificationEmail = new Noty({
                    killer: false,
                    timeout: 5000,
                    type: 'success',
                    text: 'A temporary password is emailed to '+vm.user.email,
                    theme: 'metroui',
                    layout: 'topRight'
                })
                notification.show();
                notificationEmail.show();

                vm.tempPass = response.data.user.tempPass;
                vm.$router.push({
                    path: "/user-list"
                });

                // vm.$toast.success(vm.roles.filter(item => item.value == vm.user.role)[0].name+': '+vm.user.name+' is created.', {
                //     position: 'top-right',
                //     timeout: 500000,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     draggablePercent: 0.6,
                //     showCloseButtonOnHover: false,
                //     hideProgressBar: true,
                //     closeButton: "button",
                //     icon: true
                // });
                // vm.$toast.success('A temporary password is emailed to '+vm.user.email, {
                //     position: 'top-right',
                //     timeout: 500000,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     draggablePercent: 0.6,
                //     showCloseButtonOnHover: false,
                //     hideProgressBar: true,
                //     closeButton: "button",
                //     icon: true
                // });
            })
            .catch(function (error) {
                loader.hide();
                vm.listLoading = false;
                vm.usernameTaken = false;
                vm.emailTaken = false;
                if(error.response.data.systemInformation[0].errorCode === "100"){
                    vm.usernameTaken = true;
                }
                if(error.response.data.systemInformation[0].errorCode === "101"){
                    vm.emailTaken = true;
                }
                console.error(error);
            });
        }
    },
    getContactNo(e){
        if(e.phoneNumber !== undefined && !e.isValid){
            this.user.contactcheck = true;
        }
        else{
            this.user.contactcheck = false;
        }
    },
    getResiContactNo(e){
        if(e.phoneNumber !== undefined && !e.isValid){
            this.user.contactResicheck = true;
        }
        else{
            this.user.contactResicheck = false;
        }
    },
    getPGContactNo(e){
        if(e.phoneNumber !== undefined && !e.isValid){
            this.user.pgcontactcheck = true;
        }
        else{
            this.user.pgcontactcheck = false;
        }
    },
    getPGResiContactNo(e){
        if(e.phoneNumber !== undefined && !e.isValid){
            this.user.pgcontactResicheck = true;
        }
        else{
            this.user.pgcontactResicheck = false;
        }
    },
    getEContactNo(e){
        if(e.phoneNumber !== undefined && !e.isValid){
            this.user.econtactcheck = true;
        }
        else{
            this.user.econtactcheck = false;
        }
    },
    getEResiContactNo(e){
        if(e.phoneNumber !== undefined && !e.isValid){
            this.user.econtactResicheck = true;
        }
        else{
            this.user.econtactResicheck = false;
        }
    }
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
    });
  },
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
    padding-bottom: 30px;
}
.pair-card-sub{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}

.sub-card .v-toolbar__title {
    font-size: 1rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.v-application .v-card .pair-card{

  /* box-shadow: 
    0px 8px 9px -5px rgb(0 0 0 / 20%), 
    0px 15px 22px 2px rgb(0 0 0 / 14%), 
    0px 6px 28px 5px rgb(0 0 0 / 12%) !important; */
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}
.main-card{
    padding-bottom:50px;
}
.passportPreview{
    height: 100%;
}
</style>
