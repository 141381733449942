var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"charts-page"},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-6",attrs:{"no-gutters":""}},[_c('h1',{staticClass:"page-title"},[_vm._v("Charts")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize button-shadow",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("Latest Reports")])]}}])})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Apex Line Chart")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ApexChart',{attrs:{"type":"area","height":"350","options":_vm.apexArea.options,"series":_vm.apexArea.series}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Apex Heatmap Chart")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ApexChart',{attrs:{"type":"heatmap","height":"350","options":_vm.heatMap.options,"series":_vm.heatMap.series}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Apex Dashed Line Chart")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0 pb-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12"}},[_c('ApexChart',{attrs:{"type":"line","height":"350","options":_vm.apexLines.options,"series":_vm.apexLines.series}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Apex Pie Chart")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-7",attrs:{"cols":"12"}},[_c('ApexChart',{attrs:{"type":"donut","height":_vm.$vuetify.breakpoint.smAndDown ? 300 : 350,"options":_vm.apexPie.options,"series":_vm.apexPie.series}})],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }