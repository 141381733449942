<template>
   <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
              <v-card class="dashboardcard">
                <v-card-title> <v-icon size="28" class="pageicon">menu_book</v-icon>Course</v-card-title>
                
                <div v-for="(cl, c) in intakes" :key="c">
                    <v-card class="pair-card" elevation="5" >
                        <v-toolbar dense color="teal darken-1" dark >
                            <v-btn icon>
                                <v-icon>auto_stories</v-icon>
                            </v-btn>
                            <v-toolbar-title>Intake: {{cl.name}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                        <v-row class="row-card">
                            <v-col cols="12" sm="2" xs="2" >
                                <v-chip class="ma-2" color="red darken-2" label 
                                text-color="white">
                                <v-icon left>
                                    auto_stories
                                </v-icon>
                                Name: 
                                </v-chip>
                            </v-col>
                            
                            <v-col cols="12" sm="9" xs="9" >
                                <v-text-field
                                    solo
                                    :value="`${cl.name}`"
                                    readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            <v-col cols="12" sm="2" xs="2" >
                                <v-chip class="ma-2" color="light-blue darken-4" label 
                                text-color="white">
                                <v-icon left>
                                    timelapse
                                </v-icon>
                                Duration: 
                                </v-chip>
                            </v-col>
                            
                            <v-col cols="12" sm="9" xs="9" >
                                <v-text-field
                                    solo
                                    :value="`From ${cl.start} until ${cl.end}`"
                                    readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card">
                            <v-col cols="12" sm="2" xs="2" >
                                <v-chip class="ma-2" color="light-green darken-3" label 
                                text-color="white">
                                <v-icon left>
                                    military_tech
                                </v-icon>
                                Status: 
                                </v-chip>
                            </v-col>
                            
                            <v-col cols="12" sm="9" xs="9" >
                                <v-text-field
                                    solo
                                    :value="`${cl.status}`"
                                    readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="row-card" v-if="cl.semesters.length > 0">
                            <v-col cols="12" sm="2" xs="2" >
                                <v-chip class="ma-2" color="cyan darken-4" label 
                                text-color="white">
                                <v-icon left>
                                    history_edu
                                </v-icon>
                                Semester: 
                                </v-chip>
                            </v-col>
                            <v-col cols="12" sm="10" xs="10" >
                                <v-card class="pair-card" elevation="15" >
                                    <v-row class="row-card"  >
                                            <v-col cols="12" sm="4" xs="4" 
                                                v-for="(sl, s) in cl.semesters" :key="`sem`+s" 
                                                >

                                                <v-list-item v-bind:class="[cl.currentSemester.semester === s+1 ? 'currentsemesterframe' : '' ]">
                                                    <v-list-item-content>
                                                        <v-list-item-title>Semester: {{s+1}}</v-list-item-title>
                                                        <v-list-item-subtitle>Start: {{sl.start}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle>End: {{sl.end}}</v-list-item-subtitle>
                                                        <v-list-item-subtitle v-show="cl.currentSemester.semester === s+1" style="font-weight:bold;font-size:16px">Current Semester</v-list-item-subtitle>
                                                        <v-spacer class="subtitle"></v-spacer>
                                                        <v-list-item-subtitle>{{emptyString}}</v-list-item-subtitle>
                                                        <v-list-item-title v-if="sl.timeTablePdfDatas.length > 0">TimeTable</v-list-item-title>

                                                        <v-list-item-subtitle v-for="(al,p) in sl.timeTablePdfDatas" :key="`semtt`+p" >
                                                            <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                <v-btn small color="primary" id="search-btn" class="ma-2 white--text ttbtn" 
                                                                                
                                                                            v-bind="attrs" v-on="on"
                                                                                @click="al.dialogAction = true">
                                                                                    <span style="white-space: normal;" v-text="al.name.length < 18 ? al.name : al.name.substring(0,18) + '...'">
                                                                                    </span>
                                                                                    <v-icon right dark >auto_stories</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-card>
                                                                    <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                                                    <v-toolbar color="indigo" dark dense>
                                                                        <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                                                    </v-toolbar>
                                                                    <v-divider></v-divider>
                                                                    <v-card-text style="height: 800px;">
                                                                        <v-container>
                                                                            <v-row>
                                                                                <v-col cols="12" sm="12" md="12" >
                                                                                    <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                        @click="$refs['myTimeTablePdfComponent'+c+''+s+''+ p][0].print()">
                                                                                        print
                                                                                        <v-icon right dark >print</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                        @click="downloadPDF(al.data,al.name)">
                                                                                        Download
                                                                                        <v-icon right dark >file_download</v-icon>
                                                                                    </v-btn>
                                                                                    <pdf 
                                                                                        :ref="`myTimeTablePdfComponent${c}${s}${p}`"
                                                                                        v-for="i in al.numPages"
                                                                                        :key="i"
                                                                                        :page="i" 
                                                                                        :src="al.data">
                                                                                    </pdf>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-container>
                                                                    </v-card-text>
                                                                    <v-divider></v-divider>
                                                                    <v-card-actions>
                                                                    <v-btn
                                                                        color="blue darken-1"
                                                                        text
                                                                        @click="al.dialogAction = false"
                                                                    >
                                                                        Close
                                                                    </v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- FOR Other -->
                        <v-row class="row-card" v-if="cl.semesters.length === 0">
                            <v-col cols="12" sm="2" xs="2" >
                                <v-chip class="ma-2" color="cyan darken-4" label 
                                text-color="white">
                                <v-icon left>
                                    history_edu
                                </v-icon>
                                Time Table: 
                                </v-chip>
                            </v-col>
                            <v-col cols="12" sm="10" xs="10" >
                                <v-card class="pair-card" elevation="15" >
                                    <v-row class="row-card"  >
                                            <v-col cols="12" sm="4" xs="4" 
                                                v-for="(al,p) in cl.timeTablePdfDatas" :key="`tt`+p" 
                                                >
                                                <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small color="primary" id="search-btn" class="ma-2 white--text ttbtn" 
                                                                    
                                                                v-bind="attrs" v-on="on"
                                                                    @click="al.dialogAction = true">
                                                                        <span style="white-space: normal;" v-text="al.name.length < 18 ? al.name : al.name.substring(0,18) + '...'">
                                                                        
                                                                        </span>
                                                                        <v-icon right dark >auto_stories</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                                        <v-toolbar color="indigo" dark dense>
                                                            <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                                        </v-toolbar>
                                                        <v-divider></v-divider>
                                                        <v-card-text style="height: 800px;">
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12" md="12" >
                                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                            @click="$refs['myOtherTimeTablePdfComponent'+c+'' + p][0].print()">
                                                                            print
                                                                            <v-icon right dark >print</v-icon>
                                                                        </v-btn>
                                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                            @click="downloadPDF(al.data,al.name)">
                                                                            Download
                                                                            <v-icon right dark >file_download</v-icon>
                                                                        </v-btn>
                                                                        <pdf 
                                                                            :ref="`myOtherTimeTablePdfComponent${c}${p}`"
                                                                            v-for="i in al.numPages"
                                                                            :key="i"
                                                                            :page="i" 
                                                                            :src="al.data">
                                                                        </pdf>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card-text>
                                                        <v-divider></v-divider>
                                                        <v-card-actions>
                                                        <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="al.dialogAction = false"
                                                        >
                                                            Close
                                                        </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                    </v-card>
                </div>
                
                <div v-if="list.length == 0">
                    <v-card class="pair-card" elevation="5" >
                        <v-toolbar dense color="light-blue darken-4" dark >
                            <v-btn icon>
                            <v-icon>bookmarks</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title >  </v-card-title>
                        <v-row class="row-card">
                            <v-col cols="12" sm="12" xs="12" >
                            There is no news/posts from admin. Do come back and check it later.
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
                
                <div v-for="(item, index) in list" :key="`ann`+index">
                    <v-card class="pair-card" elevation="5" >
                    <v-toolbar dense color="light-blue darken-4" dark >
                        <v-btn icon>
                          <v-icon>bookmarks</v-icon>
                        </v-btn>
                        <v-toolbar-title>Announcement: {{item.title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title v-show="!$vuetify.breakpoint.xs">Posted On {{item.timestamp}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-title >  </v-card-title>
                    <v-row class="row-card" v-show="$vuetify.breakpoint.xs">
                        <v-col cols="12" sm="12" xs="12" >
                            Posted on {{item.timestamp}}
                        </v-col>
                    </v-row>
                    <v-row class="row-card">
                        <v-col cols="12" sm="12" xs="12" >
                          <quill-editor
                                ref="myQuillEditor"
                                v-model="item.content"
                                :options="editorOption"
                                :disabled='disabled'
                            />
                        </v-col>
                    </v-row>
                    <div v-for="(al, a) in item.pdfDatas" :key="`annpdf`+a">
                      <v-row class="row-card" v-if="al.expandable">
                          <v-col cols="12" sm="12" xs="12" class="pdfExpand">
                            <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="$refs['myPdfComponent'+index+'' + a][0].print()">
                                                            print
                                                            <v-icon right dark >print</v-icon>
                                                        </v-btn>
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="downloadPDF(al.data,al.name)">
                                                            Download
                                                            <v-icon right dark >file_download</v-icon>
                                                        </v-btn>
                            <pdf 
                                :ref="`myPdfComponent${index}${a}`"
                                v-for="i in al.numPages"
                                :key="i"
                                :page="i" 
                                :src="al.data"
                                >
                            </pdf>
                          </v-col>
                      </v-row>
                      <v-row class="row-card" v-if="!al.expandable">
                          <v-col cols="12" sm="12" xs="12" >
                            <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                    <template v-slot:activator="{ on, attrs }">
                                       <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                    
                                                v-bind="attrs" v-on="on"
                                                    @click="al.dialogAction = true">
                                                        <span style="white-space: normal;">
                                                        {{ al.name }}
                                                        </span>
                                                        <v-icon right dark >auto_stories</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                        <v-toolbar color="indigo" dark dense>
                                            <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                        </v-toolbar>
                                        <v-divider></v-divider>
                                        <v-card-text style="height: 800px;">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="12" >
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="$refs['myPdfComponent'+index+'' + a][0].print()">
                                                            print
                                                            <v-icon right dark >print</v-icon>
                                                        </v-btn>
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="downloadPDF(al.data,al.name)">
                                                            Download
                                                            <v-icon right dark >file_download</v-icon>
                                                        </v-btn>
                                                        <pdf 
                                                            :ref="`myPdfComponent${index}${a}`"
                                                            v-for="i in al.numPages"
                                                            :key="i"
                                                            :page="i" 
                                                            :src="al.data">
                                                        </pdf>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="al.dialogAction = false"
                                        >
                                            Close
                                        </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                          </v-col>
                      </v-row>
                    </div>
                        
                    </v-card>
                </div>
                <div>
                    <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                    :total-visible="7"
                    ></v-pagination>
                </div>
              </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import pdf from 'vue-pdf';

export default {
  name: "StudentCourseMain",
  components: {
    pdf
  },
  data() {
    return {
      list: [],
      intakes: [],
      total: 0,
      listLoading: true,
      editorOption: {
          readOnly: true,
          theme: 'snow',
          modules: {
              toolbar : false
          }
      },
      disabled: true,
      emptyString: '  ',
      page: 1,
      totalItem: 0,
      numberOfPages: 0,
    };
  },
  created() {
      this.getIntakeList();
    this.getAnnouncementList();
  },
  computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
  },
  watch: {
      page: {
         handler() {
            this.getAnnouncementList();
         },
      },
      deep: true,
  },
  methods: {
    downloadPDF(e,filename){
            
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        //var blob = new Blob([e], { type: "application/pdf" }),
        var blob = this.dataURItoBlob(e);
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    },
    dataURItoBlob(dataURI){
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
    },
    getIntakeList() {
      this.listLoading = true
      var vm = this;
      const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
        };

      let loader = this.$loading.show({
                    // Optional parameters
                    
                    });

      let url = process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/student?&userId='+localStorage.getItem('id');
      
      this.$http.get(url,config)
        .then(response => {
            vm.intakes = response.data.intakes;
            
            vm.intakes.map(item => {
                if(item.status === undefined){
                    item.status = 'Pending';
                }

                if(item.currentSemester === undefined){
                    item.currentSemester = { semester : -1 };
                }

                if(item.timeTablePdfDatas.length >0){
                    item.timeTablePdfDatas.map(b => {
                        b.name = b.name.substring(0, b.name.length-4);
                        vm.$set(b,'dialogAction',false);
                    })
                }

                if(item.semesters.length > 0){
                    item.semesters.map(a => {
                        a.timeTablePdfDatas.map(b => {
                            b.name = b.name.substring(0, b.name.length-4);
                            vm.$set(b,'dialogAction',false);
                        })
                    })
                }
            })

            

            vm.listLoading = false;
            loader.hide();
        })
        .catch(function (error) {
            vm.listLoading = false;
            loader.hide();
            console.error(error);
        });

    },
    getAnnouncementList() {
      this.listLoading = true
      var vm = this;

      
      const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
        };

      let loader = this.$loading.show({
                    // Optional parameters
                    //container: this.$refs.formContainer,
                    //isFullPage: false
                    });

      let listpage = this.page - 1;
      let url = process.env.VUE_APP_MICROSERVICE_URL+'/announcement/get/student?&userId='+localStorage.getItem('id')+'&category=Course&page='+listpage+'&size=5&sort=postedOn&desc=true';
      
      this.$http.get(url,config)
        .then(response => {
            vm.list = response.data.list
            vm.list.map(item => {
              item.timestamp = moment(item.postedOn).format('DD/MM/YYYY hh:mm a');
              item.pdfDatas.map(a => {
                vm.$set(a,'dialogAction',false);
              })

            })
            vm.totalItem= response.data.totalItem;
            vm.numberOfPages = response.data.totalPage;
            vm.listLoading = false;
            loader.hide();
        })
        .catch(function (error) {
            vm.listLoading = false;
            loader.hide();
            console.error(error);
        });

    }
  }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
.v-toolbar__title {
    font-size: 1rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dashboardcard{
  padding-bottom: 30px;
}
.pdfExpand{
    border: 1px solid #0c0c0c;
    margin-top: 20px;
    margin-bottom: 20px;
}
.divPdfExpand{
    margin-top: 20px;
}
.currentsemesterframe{
    border-style:solid;
    border-color: rgb(49 148 0);
}
.ttbtn{
    font-size:11px;
}
.subtitle{
    margin-bottom:20px
}
@media (min-width: 1264px){
    /deep/ iframe{
        width: 500px;
        height: 300px;
    }
}
</style>
