<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
            <v-card class="mainC">
              <v-card-title> <v-icon size="28" class="pageicon">monetization_on</v-icon>Finance Details</v-card-title>

                <!-- Brief student info -->
                <v-card class="pair-card personalCard" elevation="11" >
                    <v-toolbar dense color="teal darken-1" dark >
                        <v-btn icon>
                            <v-icon>info</v-icon>
                        </v-btn>
                        <v-toolbar-title>Financial Details</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-title >  </v-card-title>
                    <v-row class="row-card">
                        <v-col cols="12" sm="4" md="4">
                            <v-currency-field 
                                label="Total Oustanding Amount"
                                filled
                                v-model="outstandingTotal"
                                prefix="RM"
                                readonly/>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-currency-field 
                                label="Total Paid Amount"
                                filled
                                v-model="paidTotal"
                                prefix="RM"
                                readonly/>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-currency-field 
                                label="Outstanding Balance"
                                filled
                                v-model="calcTotal"
                                :allow-negative="true"
                                prefix="RM"
                                readonly/>
                        </v-col>
                    </v-row>
                </v-card>

                  

                  
            </v-card>

             <div v-for="(item, index) in allIntakesList" :key="`existing-intake-`+index">
                <v-card class="existingCard" v-if="checkExistFinance(item.id) || item=='Other/Miscellaneous'">
                    <v-card-title v-if="item.name !== undefined" > <v-icon size="28" class="pageicon">payments</v-icon>Payments for Intake: {{item.name}} </v-card-title>
                    <v-card-title v-if="item.name === undefined" > <v-icon size="28" class="pageicon">payments</v-icon>Payments for Other/Miscellaneous </v-card-title>
                    
                    <div v-for="(fl, f) in existFinance" :key="`existing-f-`+f">
                        <v-card v-if="fl.intakeId === item.id" class="pair-card" elevation="11" >
                                <v-toolbar dense color="indigo darken-1" dark >
                                    <v-btn icon>
                                        <v-icon>info</v-icon>
                                    </v-btn>
                                    <v-toolbar-title >{{fl.docName}}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-card-title >  </v-card-title>
                                <v-row class="row-card">
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field
                                            label="Doc Date"
                                            v-model="fl.docDate"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-currency-field 
                                        label="Outstanding Amount"
                                        prefix="RM"
                                        v-model="fl.outstandingAmt" 
                                        readonly/>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-currency-field 
                                        label="Paid Amount"
                                        prefix="RM"
                                        v-model="fl.paidAmt" 
                                        readonly/>
                                    </v-col>
                                </v-row>
                                <v-row class="row-card">
                                    
                                    <v-col cols="12" sm="4" >
                                        <StudentFinanceDialog :financeId="fl.id" :userId="user.id"
                                            @successChanged="findExistingFinanceByStudentID"/>
                                    </v-col>
                                </v-row>
                        </v-card>

                        <!-- This is for only Other/Miscellaneous -->
                        <v-card v-if="fl.intakeId === 'Other/Miscellaneous' && item === 'Other/Miscellaneous'" class="pair-card" elevation="11" >
                                <v-toolbar dense color="brown darken-1" dark >
                                    <v-btn icon>
                                        <v-icon>info</v-icon>
                                    </v-btn>
                                    <v-toolbar-title >{{fl.docName}}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-card-title >  </v-card-title>
                                <v-row class="row-card">
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field
                                            label="Doc Date"
                                            v-model="fl.docDate"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-currency-field 
                                        label="Outstanding Amount"
                                        prefix="RM"
                                        v-model="fl.outstandingAmt" 
                                        readonly/>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-currency-field 
                                        label="Paid Amount"
                                        prefix="RM"
                                        v-model="fl.paidAmt" 
                                        readonly/>
                                    </v-col>
                                </v-row>
                                <v-row class="row-card">
                                    
                                    <v-col cols="12" sm="4" >
                                        <StudentFinanceDialog :financeId="fl.id" :userId="user.id"
                                            @successChanged="findExistingFinanceByStudentID"/>
                                    </v-col>
                                </v-row>
                        </v-card>
                    </div>
              
            </v-card>
            </div>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import StudentFinanceDialog from "./StudentFinance-dialog.vue";
import 'vuejs-noty/dist/vuejs-noty.css'
import Noty from 'noty';
import { validationMixin } from 'vuelidate';
import { required, requiredUnless } from 'vuelidate/lib/validators';
import pdf from 'vue-pdf';

export default {
  mixins: [validationMixin],
  validations: {
    finance:{
        
    }
  },
  components: {
    StudentFinanceDialog
  },
  name: "Student-Finance",
  data() {
    return {
        fileupload: null,
        menudocDate: false,
        menufldocDate: false,
        user: {
            passport: {
                data: null
            },
            intakeIds: []
        },
        existFinance: [],
        finance: {
            studentId: null,
            intakeId: null,

            pdfDatas: [],

            outstandingAmt:0,
            paidAmt: 0,

            docName: null,
            docNo: null,
            docDate: null,

            active: true
        },
        intakesList: [],
        allIntakesList: []
    };
  },
  computed: {
      paidTotal(){
        return this.existFinance.filter(item => item.active === true).reduce((acc,val) => acc + val.paidAmt, 0);
      },
      outstandingTotal() {
        return this.existFinance.filter(item => item.active === true).reduce((acc,val) => acc + val.outstandingAmt, 0);
      },
      calcTotal(){
          return this.existFinance.filter(item => item.active === true).reduce((acc,val) => acc + val.outstandingAmt, 0)
          - this.existFinance.filter(item => item.active === true).reduce((acc,val) => acc + val.paidAmt, 0);
      }
  },
  created(){
        this.findUserByID();
        
  },
  methods: {
        checkExistFinance(intakeId){
          var vm = this;
          let found = vm.existFinance.filter(element => element.intakeId === intakeId);
          if(found.length != 0){
              return true;
          }else{
              let foundEnrolled = vm.user.intakeIds.filter(element => element === intakeId);
              if(foundEnrolled.length != 0){
                return true;
              }
              return false;
          }
        },
        downloadPDF(e,filename){
            
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            //var blob = new Blob([e], { type: "application/pdf" }),
            var blob = this.dataURItoBlob(e);
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        dataURItoBlob(dataURI){
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            var ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            var blob = new Blob([ab], {type: mimeString});
            return blob;
        },
        deletePDF(index){
            this.finance.pdfDatas.splice(index,1);
        },
        addPDF(e){
            var vm = this;
            if(e !== null){
                const selectedImage = e;
                const readerBinary = new FileReader();
                const readerDataUrl = new FileReader();
                var numpage= 1;
                readerBinary.onload= (f=> {
                    numpage = f.target.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                    readerDataUrl.readAsDataURL(selectedImage);
                    
                });
                readerDataUrl.onload= (f=> {
                    let pdfdata = { 
                            name: e.name, 
                            data: f.target.result,
                            dialogAction: false,
                            numPages: numpage
                    };
                    vm.finance.pdfDatas.push(pdfdata);
                    vm.fileupload = null;
                });
                readerBinary.readAsBinaryString(selectedImage);
            }
        },
        getIntakeListAll() {
            this.listLoading = true
            var vm = this;
            const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

            let loader = this.$loading.show({
                            
                            });

            let url = process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/direct';
            
            this.$http.get(url,config)
                .then(response => {
                    vm.allIntakesList = response.data.intakes;
                    vm.allIntakesList.push("Other/Miscellaneous");
                    vm.findExistingFinanceByStudentID();
                    vm.listLoading = false;
                    loader.hide();
                })
                .catch(function (error) {
                    vm.listLoading = false;
                    loader.hide();
                    console.error(error);
                });

        },
        getIntakeList() {
            this.listLoading = true
            var vm = this;
            const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

            let loader = this.$loading.show({
                            
                            });

            let url = process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/student?&userId='+localStorage.getItem('id');
            
            this.$http.get(url,config)
                .then(response => {
                    vm.intakesList = response.data.intakes;
                    vm.intakesList = vm.intakesList.filter(item => {
                        let found = vm.user.intakeIds.filter(b => b === item.id);
                        if(found.length > 0){
                            return item;
                        }
                    })

                    vm.intakesList.push("Other/Miscellaneous");
                    vm.listLoading = false;
                    loader.hide();
                })
                .catch(function (error) {
                    vm.listLoading = false;
                    loader.hide();
                    console.error(error);
                });

        },
        findUserByID(){
            let loader = this.$loading.show({
                        // Optional parameters
                        
                    });
            this.listLoading = true
            var vm = this;
            const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };
            this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/identity/get?id='+localStorage.getItem('id'),config)
                .then(response => {
                    vm.user = response.data.user;
                    vm.finance.studentId = vm.user.id;
                    vm.getIntakeList();
                    vm.getIntakeListAll();
                    loader.hide();
                    
                })
                .catch(function (error) {
                    loader.hide();
                    vm.listLoading = false;
                    console.error(error);
                });
        },
        findExistingFinanceByStudentID(){
            let loader = this.$loading.show({
                        // Optional parameters
                        
                    });
            this.listLoading = true
            var vm = this;
            const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };
            this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/finance/get/student/finance/stud?studentId='+localStorage.getItem('id'),config)
                .then(response => {
                    vm.existFinance = response.data.finances;
                    vm.existFinance.map(item => {
                        vm.$set(item,'dialogAction',false);
                    })
                    loader.hide();
                    
                })
                .catch(function (error) {
                    loader.hide();
                    vm.listLoading = false;
                    console.error(error);
                });
        }
        
   }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
.intakeselect /deep/ .v-text-field{
      width: 100%;
}
.printbtn{
    margin-bottom: 20px !important;
}
.expandcheckbox{
    margin-top: 7px;
}
.passportPreview{
    height: 100%;
    width: 100%;
}
.existingCard{
    margin-top:50px;
    padding-bottom:30px;
}

.v-application .v-card .personalCard{

  /* box-shadow: 
    0px 8px 9px -5px rgb(0 0 0 / 20%), 
    0px 15px 22px 2px rgb(0 0 0 / 14%), 
    0px 6px 28px 5px rgb(0 0 0 / 12%) !important; */
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}
.mainC{
    padding-bottom:10px;
}
</style>
