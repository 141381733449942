<template>
    <div ref="formContainer">
        <v-dialog v-model="dialog" scrollable max-width="1200px" >
           <template #activator="{ on: dialog }">  
                    <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">  
                            <v-btn color="primary" id="search-btn" class="ma-2 white--text"
                            v-on="{ ...tooltip, ...dialog }"
                            @click="getFinanceByID">
                                    <span style="white-space: normal;">
                                    View More
                                    </span>
                                    <v-icon right dark >info</v-icon>
                            </v-btn>
                        </template>
                        <span>Click here to view your receipt</span>
                    </v-tooltip>
                </template>
            <v-card>
                <v-toolbar color="indigo" dark dense>
                    <v-toolbar-title>{{finance.docName}}</v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text style="height: 800px;">
                    <v-container>
                            <v-row class="row-card">
                                <v-col cols="12" sm="4" >
                                    <v-text-field
                                        label="Doc Name"
                                        v-model="finance.docName"
                                        required
                                        readonly

                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" >
                                    <v-text-field
                                        label="Doc No"
                                        v-model="finance.docNo"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" >
                                        <v-menu
                                            ref="menufldocDate"
                                            v-model="finance.menufldocDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            readonly
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                v-model="finance.docDate"
                                                label="Doc Date"
                                                readonly
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                readonly
                                                v-model="finance.docDate"
                                                @input="finance.menufldocDate = false"
                                            ></v-date-picker>
                                        </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="row-card">
                            <v-col cols="12" sm="4" >
                                <v-currency-field 
                                    label="Outstanding Amount"
                                    prefix="RM"
                                    v-model="finance.outstandingAmt"
                                    readonly/>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-currency-field 
                                    label="Paid Amount"
                                    prefix="RM"
                                    v-model="finance.paidAmt"
                                    readonly/>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-select
                                    v-model="finance.intakeId"
                                    :items="intakesList"
                                    item-text="name"
                                    item-value="id"
                                    attach
                                    chips
                                    label="Intake"
                                    readonly
                                ></v-select>
                            </v-col>
                    </v-row>

                    <v-card class="receiptCard">
                        <v-toolbar color="blue darken-3" dark dense>
                            <v-btn icon>
                          <v-icon>receipt_long</v-icon>
                        </v-btn>
                            <v-toolbar-title>Receipts</v-toolbar-title>
                        </v-toolbar>
                        <v-row class="row-card">
                            
                            <v-col cols="12" sm="3" md="3" v-for="(al, a) in finance.pdfDatas" :key="`edit`+a" >
                                
                                        <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                            <template v-slot:activator="{ on, attrs }">
                                            
                                                    <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                            
                                                        v-bind="attrs" v-on="on"
                                                            @click="al.dialogAction = true">
                                                                <span style="white-space: normal;" v-text="al.name.length < 28 ? al.name : al.name.substring(0,28) + '...'">
                                                                </span>
                                                                <!-- <span style="white-space: normal;">
                                                                {{ al.name }}
                                                                </span> -->
                                                                <v-icon right dark >info</v-icon>
                                                    </v-btn>
                                            </template>
                                            <v-card>
                                                
                                                <v-toolbar color="indigo" dark dense>
                                                    <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                                </v-toolbar>
                                                <v-divider></v-divider>
                                                <v-card-text style="height: 800px;">
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12" sm="12" md="12" >
                                                                <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                    @click="$refs['myPdfComponentedit' + a][0].print()">
                                                                    print
                                                                    <v-icon right dark >print</v-icon>
                                                                </v-btn>
                                                                <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                    @click="downloadPDF(al.data,al.name)">
                                                                    Download
                                                                    <v-icon right dark >file_download</v-icon>
                                                                </v-btn>
                                                                <pdf 
                                                                    :ref="`myPdfComponentedit${a}`"
                                                                    v-for="i in al.numPages"
                                                                    :key="i"
                                                                    :page="i" 
                                                                    :src="al.data">
                                                                </pdf>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="al.dialogAction = false"
                                                >
                                                    Close
                                                </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                
                            </v-col>
                        </v-row> 
                    </v-card>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import 'vuejs-noty/dist/vuejs-noty.css'
import { validationMixin } from 'vuelidate'
import {  sameAs, required } from 'vuelidate/lib/validators'
import pdf from 'vue-pdf';
import 'vuejs-noty/dist/vuejs-noty.css'
import Noty from 'noty';

export default {

  name: 'financeEdit',
  mixins: [validationMixin],
  validations: {
    finance:{
        // docName: { required }
    }
  },
  components: {
    pdf
  },
  props: {
     financeId: String,
     userId: String
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
  data() {
    return {
        dialog: false,
        finance: {
            docName: null,
            docNo: null,
            docDate: null
        },
        intakesList: [],
        allIntakesList: [],
        fileupload: null
    }
  },
  created() {
    this.getIntakeList();
  },
  methods: {
    downloadPDF(e,filename){
            
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        //var blob = new Blob([e], { type: "application/pdf" }),
        var blob = this.dataURItoBlob(e);
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    },
    dataURItoBlob(dataURI){
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
    },
    deletePDF(index){
        this.finance.pdfDatas.splice(index,1);
    },
    addPDF(e){
        var vm = this;
        if(e !== null){
            const selectedImage = e;
            const readerBinary = new FileReader();
            const readerDataUrl = new FileReader();
            var numpage= 1;
            readerBinary.onload= (f=> {
                numpage = f.target.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                readerDataUrl.readAsDataURL(selectedImage);
                
            });
            readerDataUrl.onload= (f=> {
                let pdfdata = { 
                        name: e.name, 
                        data: f.target.result,
                        dialogAction: false,
                        numPages: numpage
                };
                vm.finance.pdfDatas.push(pdfdata);
                vm.fileupload = null;
            });
            readerBinary.readAsBinaryString(selectedImage);
        }
    },
    closeDialog(){
        //this.$props.value= false;
        this.$emit('closedialog', false);
    },
    getIntakeListAll() {
        this.listLoading = true
        var vm = this;
        const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

        let loader = this.$loading.show({
                        // Optional parameters
                        });

        let url = process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/direct';
        
        this.$http.get(url,config)
            .then(response => {
                vm.allIntakesList = response.data.intakes;
                vm.checkExistFinance();
                vm.listLoading = false;
                loader.hide();
            })
            .catch(function (error) {
                vm.listLoading = false;
                loader.hide();
                console.error(error);
            });

    },
    checkExistFinance(){
        var vm = this;
        let found = vm.intakesList.filter(item => item.id === vm.finance.intakeId);
        if(found.length === 0){
            let foundFromAll = vm.allIntakesList.filter(item => item.id === vm.finance.intakeId);
            if(foundFromAll.length != 0){
                vm.intakesList.push(foundFromAll[0]);
            }
        }
    },
    getIntakeList() {
        this.listLoading = true
        var vm = this;
        const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

        let loader = this.$loading.show({
                        // Optional parameters
                        });

        let url = process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/student?&userId='+this.userId;
        
        this.$http.get(url,config)
            .then(response => {
                vm.intakesList = response.data.intakes;

                vm.intakesList.push("Other/Miscellaneous");
                vm.listLoading = false;
                loader.hide();
            })
            .catch(function (error) {
                vm.listLoading = false;
                loader.hide();
                console.error(error);
            });

    },
    getFinanceByID(){
        this.listLoading = true
        let loader = this.$loading.show({
                // Optional parameters
                });
        
        var vm = this;
        const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

        this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/finance/get?id='+vm.financeId,config)
            .then(response => {
                
                vm.finance = response.data.finance;
                vm.$set(vm.finance,'menufldocDate', false);
                
                vm.finance.pdfDatas.map(item => {
                    vm.$set(item,'dialogAction', false);
                })

                vm.getIntakeListAll();
                // let found = vm.existFinance.filter(item => item.id === financeId);
                // if(found.length >0){
                //     let index = vm.existFinance.indexOf(found[0]);
                //     vm.$set(vm.existFinance, index, response.data.finance);
                // }

                // /* Set dialog to true because earlier set clear it off and this is after user open the dialog*/
                // vm.existFinance.map(item => {
                //         if(item.id === financeId){
                //             vm.$set(item,'dialogAction', true);
                //             vm.$set(item,'menufldocDate', false);
                //         }
                // });
                vm.listLoading = false;
                loader.hide();
                
            })
            .catch(function (error) {
                vm.listLoading = false
                console.error(error);
                loader.hide();
            });
    },
  }
}
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

#search-btn {
    margin-left: 0px !important;
}

.popup{
    white-space: pre;
}
.receiptCard .v-toolbar__title {
    font-size: 1rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-application .v-card .receiptCard{

  /* box-shadow: 
    0px 8px 9px -5px rgb(0 0 0 / 20%), 
    0px 15px 22px 2px rgb(0 0 0 / 14%), 
    0px 6px 28px 5px rgb(0 0 0 / 12%) !important; */
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}
</style>