<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
            <v-card class="firstcard">
              <v-card-title> <v-icon size="28" class="pageicon">fact_check</v-icon>Examination</v-card-title>
                  
                  <v-row class="row-card">
                      <v-col cols="12" sm="4" >
                          <v-select
                            v-model="transcript.intake"
                            :items="intakesList"
                            item-text="name"
                            item-value="id"
                            return-object
                            attach
                            chips
                            persistent-hint
                            label="Intake"
                            v-on:change="getUserByIntakeId"
                        ></v-select>
                      </v-col>
                  </v-row>
                  
            </v-card>

            <v-card v-if="users.length > 0">

              <v-card-title> <v-icon size="28" class="pageicon">people_alt</v-icon> Students</v-card-title>
                  
                <div v-for="(item, index) in users" :key="index">
                    <v-row class="row-card">
                      <v-col cols="12" sm="4" >
                        <v-text-field
                              label="Name"
                              v-model="item.name"
                              readonly
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" >

                        <v-dialog v-model="item.dialogAction" scrollable max-width="1200px" >
                            <template v-slot:activator="{ on, attrs }">  
                                <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                    
                                v-bind="attrs" v-on="on"
                                    @click="openTranscriptDialog(index,item.id)">
                                        <span style="white-space: normal;">
                                        Upload Transcript
                                        </span>
                                        <v-icon right dark >info</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-toolbar color="indigo" dark dense>
                                    <v-toolbar-title>{{item.name}}</v-toolbar-title>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text style="height: 800px;">
                                    <v-container>
                                        <!-- This is for MQA -->
                                        <div v-if="transcript.intake.semesters.length > 0">
                                            <div v-for="(fl, f) in transcript.intake.semesters" :key="f">
                                                <v-card class="semestercard" elevation="24">
                                                <v-row class="row-card">
                                                    <v-col cols="12" sm="3" md="3" >
                                                        <v-text-field
                                                            outlined
                                                            :value="`Semester ${f+1}`"
                                                            readonly
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" sm="3" md="3" >
                                                        <v-file-input
                                                            v-model="fileupload"
                                                            accept=".pdf"
                                                            prepend-icon="menu_book"
                                                            label="Upload Transcript"
                                                            @change="addPDF($event,f+1,transcript.intake,item.id, index)"
                                                        ></v-file-input>
                                                    </v-col>

                                                    <v-col cols="12" sm="6" md="6"  >
                                                        <div v-if="checkGotExistingTranscripts(index,f+1)">
                                                            <div v-for="(al, a) in item.transcripts" :key="a">
                                                                <div v-if="f+1 === al.semester">
                                                                
                                                                    
                                                                        <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                                                            <template v-slot:activator="{ on, attrs }">  
                                                                                <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                                                    
                                                                                v-bind="attrs" v-on="on"
                                                                                    @click="al.dialogAction = true">
                                                                                        <span style="white-space: normal;">
                                                                                        {{ al.name }}
                                                                                        </span>
                                                                                        <v-icon right dark >info</v-icon>
                                                                                </v-btn>
                                                                                <v-btn small color="red" class="ma-2 white--text" 
                                                                                    @click="deleteTranscript(al.id,transcript.intake.id,item.id)">
                                                                                        <span style="white-space: normal;">
                                                                                        Delete
                                                                                        </span>
                                                                                        <v-icon right dark >delete_forever</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-card>
                                                                                <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                                                                <v-toolbar color="indigo" dark dense>
                                                                                    <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                                                                </v-toolbar>
                                                                                <v-divider></v-divider>
                                                                                <v-card-text style="height: 800px;">
                                                                                    <v-container>
                                                                                        <v-row>
                                                                                            <v-col cols="12" sm="12" md="12" >
                                                                                                <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                                    @click="$refs['myPdfComponent' + a][0].print()">
                                                                                                    print
                                                                                                    <v-icon right dark >print</v-icon>
                                                                                                </v-btn>
                                                                                                <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                                    @click="downloadPDF(al.data,al.name)">
                                                                                                    Download
                                                                                                    <v-icon right dark >file_download</v-icon>
                                                                                                </v-btn>
                                                                                                <pdf 
                                                                                                    :ref="`myPdfComponent${a}`"
                                                                                                    v-for="i in al.numPages"
                                                                                                    :key="i"
                                                                                                    :page="i" 
                                                                                                    :src="al.data">
                                                                                                </pdf>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-container>
                                                                                </v-card-text>
                                                                                <v-divider></v-divider>
                                                                                <v-card-actions>
                                                                                <v-btn
                                                                                    color="blue darken-1"
                                                                                    text
                                                                                    @click="al.dialogAction = false"
                                                                                >
                                                                                    Close
                                                                                </v-btn>
                                                                                </v-card-actions>
                                                                            </v-card>
                                                                        </v-dialog>
                                                                    
                                                                </div>
                                                            </div>                
                                                            
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                </v-card>
                                            </div>
                                        </div>
                                        

                                        <!-- This is for Others -->
                                        <div v-if="transcript.intake.semesters.length === 0">
                                            
                                                <v-row>
                                                    <v-col cols="12" sm="3" md="3" >
                                                        <v-file-input
                                                            v-model="fileupload"
                                                            accept=".pdf"
                                                            prepend-icon="menu_book"
                                                            label="Upload Transcript"
                                                            @change="addPDF($event,0,transcript.intake,item.id, index)"
                                                        ></v-file-input>
                                                    </v-col>

                                                    <v-col cols="12" sm="6" md="6"  >
                                                        <div v-if="checkGotExistingTranscripts(index,0)">
                                                            <div v-for="(al, a) in item.transcripts" :key="a">
                                                                 
                                                                        <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                                                            <template v-slot:activator="{ on, attrs }">  
                                                                                <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                                                    
                                                                                v-bind="attrs" v-on="on"
                                                                                    @click="al.dialogAction = true">
                                                                                        <span style="white-space: normal;">
                                                                                        {{ al.name }}
                                                                                        </span>
                                                                                        <v-icon right dark >info</v-icon>
                                                                                </v-btn>
                                                                                <v-btn small color="red" class="ma-2 white--text" 
                                                                                    @click="deleteTranscript(al.id,transcript.intake.id,item.id)">
                                                                                        <span style="white-space: normal;">
                                                                                        Delete
                                                                                        </span>
                                                                                        <v-icon right dark >delete_forever</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-card>
                                                                                <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                                                                <v-toolbar color="indigo" dark dense>
                                                                                    <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                                                                </v-toolbar>
                                                                                <v-divider></v-divider>
                                                                                <v-card-text style="height: 800px;">
                                                                                    <v-container>
                                                                                        <v-row>
                                                                                            <v-col cols="12" sm="12" md="12" >
                                                                                                <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                                    @click="$refs['myPdfComponent' + a][0].print()">
                                                                                                    print
                                                                                                    <v-icon right dark >print</v-icon>
                                                                                                </v-btn>
                                                                                                <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                                                                    @click="downloadPDF(al.data,al.name)">
                                                                                                    Download
                                                                                                    <v-icon right dark >file_download</v-icon>
                                                                                                </v-btn>
                                                                                                <pdf 
                                                                                                    :ref="`myPdfComponent${a}`"
                                                                                                    v-for="i in al.numPages"
                                                                                                    :key="i"
                                                                                                    :page="i" 
                                                                                                    :src="al.data">
                                                                                                </pdf>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-container>
                                                                                </v-card-text>
                                                                                <v-divider></v-divider>
                                                                                <v-card-actions>
                                                                                <v-btn
                                                                                    color="blue darken-1"
                                                                                    text
                                                                                    @click="al.dialogAction = false"
                                                                                >
                                                                                    Close
                                                                                </v-btn>
                                                                                </v-card-actions>
                                                                            </v-card>
                                                                        </v-dialog>
                                                                    
                                                                </div>              
                                                            
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                    </v-container>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="item.dialogAction = false"
                                >
                                    Close
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                </div>
                  
                  
            </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import 'vuejs-noty/dist/vuejs-noty.css';
import Noty from 'noty';
import { validationMixin } from 'vuelidate';
import pdf from 'vue-pdf';

export default {
  mixins: [validationMixin],
  name: "Dashboard",
  components: {
    pdf
  },
  data() {
    return {
      transcript:
      {
          intake: null
      },
      intakesList: [],
      users: [],
      fileupload: null
    };
  },
  created(){
        this.getIntakes();
  },
  methods: {
    downloadPDF(e,filename){
            
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        //var blob = new Blob([e], { type: "application/pdf" }),
        var blob = this.dataURItoBlob(e);
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    },
    dataURItoBlob(dataURI){
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
    },
    deleteTranscript(transcriptId,intakeId,studentId){
        this.listLoading = true

        let transcriptDTO = {
            id: transcriptId,
            intakeId: intakeId,
            studentId: studentId
        }

        let loader = this.$loading.show({
                // Optional parameters
                
                });

        var vm = this;
        const config = {
            headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
        };

        this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/transcript/delete/transcript?userName='+localStorage.getItem('username'),transcriptDTO,config)
        .then(response => {

            vm.users.map(item => {
                    if(item.id === studentId){
                        
                        vm.$set(item, 'transcripts', response.data.transcripts);

                        //Add dialogAction for PDF open
                        item.transcripts.map(a => {
                            vm.$set(a, 'dialogAction', false);
                        })
                        
                    }
                })
            loader.hide();

            //notification of successfully saved
            var notification = new Noty({
                killer: false,
                timeout: 5000,
                type: 'success',
                text: 'Transcript is deleted.',
                theme: 'metroui',
                layout: 'topRight'
            })
            notification.show();
            
        })
        .catch(function (error) {
            loader.hide();
            vm.listLoading = false;
            console.error(error);
        });
    },
    checkGotExistingTranscripts(index,semester){

        //this is for others
        if(semester === 0){
            if(this.users[index].transcripts !== undefined){
                if(this.users[index].transcripts.length > 0){
                    return true;
                }
            }else{
                return false;
            }
        }
        /* This is for MQA */
        else{
            if(this.users[index].transcripts !== undefined){
                let check = this.users[index].transcripts.filter(item => item.semester === semester);
                if(check.length > 0){
                    return true;
                }
            }else{
                return false;
            }
        }

        
    },
    openTranscriptDialog(index,studentId){
        this.users[index].dialogAction = true;

        this.getTranscript(studentId);
    },
    getTranscript(studentId){
        this.listLoading = true
        let loader = this.$loading.show({
                // Optional parameters
                
                });
        
        var vm = this;
        const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

        this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/transcript/get/transcript?intakeId='+vm.transcript.intake.id+'&studentId='+studentId,config)
            .then(response => {
                
                vm.users.map(item => {
                    if(item.id === studentId){
                        
                        vm.$set(item, 'transcripts', response.data.transcripts);

                        //Add dialogAction for PDF open
                        item.transcripts.map(a => {
                            vm.$set(a, 'dialogAction', false);
                        })
                        
                    }
                })
                vm.listLoading = false;
                loader.hide();
                
            })
            .catch(function (error) {
                vm.listLoading = false
                console.error(error);
                loader.hide();
            });
    },
    addPDF(e,semester,intake,studentId, index){
        var vm = this;
        if(e !== null){
            const selectedImage = e;
            const readerBinary = new FileReader();
            const readerDataUrl = new FileReader();
            var numpage= 1;
            readerBinary.onload= (f=> {
                numpage = f.target.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                readerDataUrl.readAsDataURL(selectedImage);
                
            });
            readerDataUrl.onload= (f=> {

                let transcript = null;
                /* This is for others */
                if(semester === 0){
                    transcript = { 
                        name: e.name, 
                        data: f.target.result,
                        intakeId: intake.id,
                        studentId: studentId,
                        numPages: numpage
                    };
                }
                /* This is for MQA */
                else{
                    transcript = { 
                        name: e.name, 
                        data: f.target.result,
                        semester: semester,
                        intakeId: intake.id,
                        studentId: studentId,
                        numPages: numpage
                    };
                }

                this.uploadTranscript(transcript,studentId);
                vm.fileupload = null;
            });
            readerBinary.readAsBinaryString(selectedImage);
        }
    },
    uploadTranscript(transcript,studentId){

        this.listLoading = true
            let loader = this.$loading.show({
                    // Optional parameters
                    
                    });

            var vm = this;
            const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

            this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/transcript/post?userName='+localStorage.getItem('username'),transcript,config)
            .then(response => {

                vm.users.map(item => {
                    if(item.id === studentId){
                        
                        vm.$set(item, 'transcripts', response.data.transcripts);

                        //Add dialogAction for PDF open
                        item.transcripts.map(a => {
                            vm.$set(a, 'dialogAction', false);
                        })
                        
                    }
                })
                loader.hide();

                //notification of successfully saved
                var notification = new Noty({
                    killer: false,
                    timeout: 5000,
                    type: 'success',
                    text: 'Transcript is uploaded.',
                    theme: 'metroui',
                    layout: 'topRight'
                })
                notification.show();
                
            })
            .catch(function (error) {
                loader.hide();
                vm.listLoading = false;
                console.error(error);
            });
    },
    getUserByIntakeId(){
        this.listLoading = true
        let loader = this.$loading.show({
                // Optional parameters
                
                });
        
        var vm = this;
        const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

        this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/transcript/get/users?intakeId='+vm.transcript.intake.id,config)
            .then(response => {
                
                vm.users = response.data.users;
                vm.users.map(item => {
                    vm.$set(item, 'dialogAction', false);
                })
                vm.listLoading = false;
                loader.hide();
                
            })
            .catch(function (error) {
                vm.listLoading = false
                console.error(error);
                loader.hide();
            });
    },
    getIntakes(){
        this.listLoading = true
        let loader = this.$loading.show({
                // Optional parameters
                
                });
        
        var vm = this;
        const config = {
                headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
            };

        this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/active',config)
            .then(response => {
                
                vm.intakesList = response.data.intakes;

                vm.listLoading = false;
                loader.hide();
                
            })
            .catch(function (error) {
                vm.listLoading = false
                console.error(error);
                loader.hide();
            });
    },
    
  }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
.firstcard{
    margin-bottom: 40px;
}
.semestercard{
    margin-bottom: 20px;
}
.printbtn{
    margin-bottom: 20px !important;
}
</style>
