<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{'drawer-mini': !DRAWER_STATE}">
    <v-list>
      <template v-for="(item, i) in calItems">
        <v-row
          v-if="item.heading"
          :key="item.heading"
          align="center">
          <v-col cols="6" class="py-5">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="(item.heading && DRAWER_STATE) ? 'show ' : 'hide'">
              {{ item.heading }}
              </span>
          </v-col>
          <v-col
            cols="6"
            class="text-center">
          </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children && DRAWER_STATE"
          :key="item.title"
          v-model="item.model"
          append-icon="">
            <template v-slot:prependIcon>
              <v-icon size="28">{{ item.icon }}</v-icon>
            </template>
            <template v-slot:activator >
              <v-list-item-content >
                <v-list-item-title
                  class="black--text">
                    {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :href="child.href ? child.href : null"
              :target="child.target"
              :to="child.link"
              link>
                <v-list-item-action v-if="child.icon">
                  <v-icon size="">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="black--text breakword">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :target="item.target"
          :href="item.href ? item.href : null"
          :to="item.link === '#' ? null : item.link"
          link>
          <v-list-item-action>
            <v-icon
              size="28"
              :color="item.color ? item.color : ''"
            >{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="black--text"
                link>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex'

  export default {
    props: {
        source: String,
    },
    data(){
      return {
        items: [
          { title: 'Dashboard', icon: 'mdi-home', link: '/dashboard' },
          { title: 'Typography', icon: 'mdi-format-size', link: '/typography' },
          { title: 'Tables', icon: 'mdi-grid-large', link: '/tables' },
          { title: 'Notifications', icon: 'mdi-bell-outline', link: '/notifications' },
          { title: 'Test', icon: 'verified_user', link: '/test' },
          {
            title: 'UI Elements',
            icon: 'mdi-image-filter-none',
            link: '/icons',
            model: false,
            children: [
              { title: 'Icons', icon: 'mdi-circle-small', link: '/icons'},
              { title: 'Charts', icon: 'mdi-circle-small', link: '/charts'},
              { title: 'Maps', icon: 'mdi-circle-small', link: '/maps'},
            ],
          },
          { divider: true },
          { heading: 'HELP' },
          { title: 'Library', icon: 'mdi-book-variant-multiple', href: 'https://flatlogic.com/templates'},
          { title: 'Support', icon: 'mdi-forum', href: 'https://flatlogic.com/forum/'},
          { title: 'FAQ', icon: 'mdi-help-circle-outline', href:'https://flatlogic.com/templates/vue-material-template'},
          { divider: true },
          { heading: 'PROJECTS' },
          { title: 'My recent', icon: 'mdi-circle-medium', color: 'warning'},
          { title: 'Starred', icon: 'mdi-circle-medium', color: 'primary'},
          { title: 'Background', icon: 'mdi-circle-medium', color: 'error'}

        ],
        sidebarWidth: 260,
        sidebarMinWidth: 96
      }
    },
    computed: {
      ...mapState(['drawer']),
      DRAWER_STATE: {
        get() {
          return this.drawer
        },
        set(newValue) {
          if (newValue === this.drawer) return;
          this.TOGGLE_DRAWER();
        }
      },
      calItems(){
        let role = localStorage.getItem('roles');
        if(role.includes('ROLE_ADMIN') || role.includes('ROLE_STAFF')){
          return [
            { title: 'Dashboard', icon: 'mdi-home', link: '/dashboard' },
            { title: 'Announcement', icon: 'campaign', link: '/add-announcement',
              model: false,
              children: [
                { title: 'Add Announcement', icon: 'add_circle_outline', link: '/add-announcement'},
                { title: 'Announcement List', icon: 'list', link: '/announcement-list'}
              ],
            },
            { title: 'Course', icon: 'class', link: '/add-course',
              model: false,
              children: [
                { title: 'Add Course', icon: 'library_add', link: '/add-course'},
                { title: 'Course List', icon: 'list', link: '/course-list'}
              ],
            },
            { title: 'Intake', icon: 'menu_book', link: '/add-intake',
              model: false,
              children: [
                { title: 'Add Intake', icon: 'note_add', link: '/add-intake'},
                { title: 'Intake List', icon: 'list', link: '/intake-list'}
              ],
            },
            { 
              title: 'User', icon: 'group', link: '/registration',
              model: false,
              children: [
                { title: 'Registration', icon: 'accessibility_new', link: '/registration'},
                { title: 'User List', icon: 'groups', link: '/user-list'},
                { title: 'User Control', icon: 'touch_app', link: '/user-control'}
              ],
            },
            { title: 'Examination', icon: 'fact_check', link: '/transcript' },
            { title: 'Finance', icon: 'monetization_on', link: '/finance'},
            { title: 'Classroom', icon: 'school', href: 'https://classroom.google.com', target: '_blank' },
            { title: 'E-Library', icon: 'library_books', link: 'https://open.umn.edu/opentextbooks',
              model: false,
              children: [
                { title: 'Pintar Librarika', icon: 'local_library', href: 'https://pintar.librarika.com', target: '_blank' },
                { title: 'Open Textbook Library', icon: 'menu_book', href: 'https://open.umn.edu/opentextbooks', target: '_blank' },
                { title: 'National Library', icon: 'card_membership', href: 'https://pnm.elib.com.my/index.php/book/category/8', target: '_blank' }
              ],
            },
          ]
        }
        else if(role.includes('ROLE_STUDENT') ){
          return [
            { title: 'Dashboard', icon: 'mdi-home', link: '/dashboard', target: '_self' },
            { title: 'Course', icon: 'class', link: '/course/main',
              model: false,
              children: [
                { title: 'Main', icon: 'auto_stories', link: '/course/main'}
              ],
            },
            { title: 'Examination', icon: 'fact_check', link: '/transcript/main',
              model: false,
              children: [
                { title: 'Main', icon: 'auto_stories', link: '/transcript/main'},
                { title: 'Result', icon: 'fact_check', link: '/transcript/result'}
              ],
            },
            { title: 'Finance', icon: 'monetization_on', link: '/finance/main',
              model: false,
              children: [
                { title: 'Main', icon: 'auto_stories', link: '/finance/main'},
                { title: 'Details', icon: 'fact_check', link: '/finance/details'}
              ],
            },
            { title: 'Classroom', icon: 'school', href: 'https://classroom.google.com', target: '_blank' },
            { title: 'E-Library', icon: 'library_books', link: 'https://open.umn.edu/opentextbooks',
              model: false,
              children: [
                { title: 'Pintar Librarika', icon: 'local_library', href: 'https://pintar.librarika.com', target: '_blank' },
                { title: 'Open Textbook Library', icon: 'menu_book', href: 'https://open.umn.edu/opentextbooks', target: '_blank' },
                { title: 'National Library', icon: 'card_membership', href: 'https://pnm.elib.com.my/index.php/book/category/8', target: '_blank' }
              ],
            },

          ]
        }
        else if(role.includes('ROLE_TEACHER')){
          return [
            { title: 'Dashboard', icon: 'mdi-home', link: '/dashboard', target: '_self' },
            { title: 'Course', icon: 'class', link: '/course/main',
              model: false,
              children: [
                { title: 'Main', icon: 'auto_stories', link: '/course/main'}
              ],
            },
            { title: 'Examination', icon: 'fact_check', link: '/transcript/main',
              model: false,
              children: [
                { title: 'Main', icon: 'auto_stories', link: '/transcript/main'}
              ],
            },
            { title: 'Finance', icon: 'monetization_on', link: '/finance/main',
              model: false,
              children: [
                { title: 'Main', icon: 'auto_stories', link: '/finance/main'}
              ],
            },
            { title: 'Classroom', icon: 'school', href: 'https://classroom.google.com', target: '_blank' },
            { title: 'E-Library', icon: 'library_books', link: 'https://open.umn.edu/opentextbooks',
              model: false,
              children: [
                { title: 'Pintar Librarika', icon: 'local_library', href: 'https://pintar.librarika.com', target: '_blank' },
                { title: 'Open Textbook Library', icon: 'menu_book', href: 'https://open.umn.edu/opentextbooks', target: '_blank' },
                { title: 'National Library', icon: 'card_membership', href: 'https://pnm.elib.com.my/index.php/book/category/8', target: '_blank' }
              ],
            },

          ]
        }
        else{
          return [
            { title: 'Dashboard', icon: 'mdi-home', link: '/dashboard', target: '_self' },
            { title: 'Course', icon: 'class', link: '/course/main',
              model: false,
              children: [
                { title: 'Main', icon: 'auto_stories', link: '/course/main'}
              ],
            },
            { title: 'Examination', icon: 'fact_check', link: '/transcript/main',
              model: false,
              children: [
                { title: 'Main', icon: 'auto_stories', link: '/transcript/main'}
              ],
            },
            { title: 'Classroom', icon: 'school', href: 'https://classroom.google.com', target: '_blank' },
            { title: 'E-Library', icon: 'library_books', link: 'https://open.umn.edu/opentextbooks',
              model: false,
              children: [
                { title: 'Pintar Librarika', icon: 'local_library', href: 'https://pintar.librarika.com', target: '_blank' },
                { title: 'Open Textbook Library', icon: 'menu_book', href: 'https://open.umn.edu/opentextbooks', target: '_blank' },
                { title: 'National Library', icon: 'card_membership', href: 'https://pnm.elib.com.my/index.php/book/category/8', target: '_blank' }
              ],
            },
            

          ]
        }
        
      }
    },
    methods: {
      ...mapActions([ 'TOGGLE_DRAWER' ]),
    }
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
