<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
            <v-card>
              <v-card-title> <v-icon size="28" class="pageicon">touch_app</v-icon>User Control</v-card-title>
                  <v-row>
                      
                  </v-row>
                  <v-row class="row-card">
                      <v-col cols="12" sm="4" >
                          <v-text-field
                              label="User email address"
                              v-model="user.email"
                              required
                              @output="$v.user.email.$touch()"
                              @blur="$v.user.email.$touch()"
                              :error-messages="!$v.user.email.required ? ['User email is required'] : user.errMsg"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" >
                          <v-btn color="indigo" class="ma-5 white--text" @click="unlockUser" >
                          Unlock
                          <v-icon right dark >lock_open</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
            </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import 'vuejs-noty/dist/vuejs-noty.css'
import Noty from 'noty';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  validations: {
    user:{
        email: { required }
    }
  },
  name: "Add-Course",
  data() {
    return {
        user: {
            email: null,
            errMsg: null
        }
    };
  },
  methods: {
        unlockUser(){

            if(this.$v.$invalid  ){
                this.$v.$touch();
            }
            else{

                this.listLoading = true
                let loader = this.$loading.show({
                    // Optional parameters
                    //container: this.$refs.formContainer,
                    //isFullPage: false
                    });

                var vm = this;
                const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

                this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/identity/unlock?email='+this.user.email,null,config)
                .then(response => {
                    loader.hide();
                    if(response.data.success){
                      //notification of successfully saved
                      vm.user.errMsg = null;
                      var notification = new Noty({
                          killer: false,
                          timeout: 5000,
                          type: 'info',
                          text: 'User : '+vm.user.email+ ' is unlocked.',
                          theme: 'metroui',
                          layout: 'topRight'
                      })
                      notification.show();
                    }else{
                      vm.user.errMsg = "User email cannot be found in the system.";
                    }
                })
                .catch(function (error) {
                    loader.hide();
                    vm.listLoading = false;
                    console.error(error);
                });
            }
        }
   }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
</style>
