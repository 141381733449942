<template>
  <v-container fluid>
    <div class="dashboard-page">
      <div ref="formContainer">
        <v-form>
          <v-container>
            <v-card>
              <v-card-title> <v-icon size="28" class="pageicon">campaign</v-icon> Add Announcement</v-card-title>
                  <v-row>
                      <v-col cols="12" sm="12" class="text-right" >
                          <v-btn color="success" class="ma-5 white--text" @click="addNewAnnouncement" >
                          Add Announcement
                          <v-icon right dark >save</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
                  <v-row class="row-card">
                      <v-col cols="12" sm="4" >
                          <v-text-field
                              label="Title"
                              v-model="announcement.title"
                              required
                              @output="$v.announcement.title.$touch()"
                              @blur="$v.announcement.title.$touch()"
                              :error-messages="!$v.announcement.title.required ? ['Title is required'] : ''"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" >
                        <v-checkbox v-model="announcement.active" :label="`Active`" ></v-checkbox>
                      </v-col>
                  </v-row>
                  <v-row class="row-card">
                      <v-col cols="12" sm="4" >
                          <v-select
                            v-model="announcement.category"
                            :items="categories"
                            attach
                            persistent-hint
                            label="Category"
                            @output="$v.announcement.category.$touch()"
                            @blur="$v.announcement.category.$touch()"
                            :error-messages="!$v.announcement.category.required ? ['Category is required'] : ''"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="announcement.category !== 'General' && announcement.category !== null">
                          <v-select
                            v-model="announcement.type"
                            :items="types"
                            attach
                            persistent-hint
                            label="Type"
                            @output="$v.announcement.type.$touch()"
                            @blur="$v.announcement.type.$touch()"
                            :error-messages="!$v.announcement.type.required ? ['Type is required'] : ''"
                        ></v-select>
                      </v-col>
                      <v-col class="intakeselect" cols="12" sm="8" 
                        v-if="announcement.category !== 'General' 
                            && (announcement.type === 'Intake' || announcement.type === 'MQA' 
                            || announcement.type === 'Other') " >
                          <v-select
                            v-model="announcement.intakeId"
                            :items="intakesList"
                            item-text="name"
                            item-value="id"
                            attach
                            chips
                            multiple
                            persistent-hint
                            label="Intake"
                            :readonly="announcement.type === 'MQA' || announcement.type === 'Other'"
                        ></v-select>
                      </v-col>
                  </v-row>

                  <v-row class="row-card">
                    <v-col cols="12" sm="4" md="4" >
                        <v-file-input
                            v-model="fileupload"
                            hint="Please note that all PDFs upload here will be display at the most bottom of every announcement."
                            persistent-hint
                            accept=".pdf"
                            prepend-icon="style"
                            label="Upload PDF"
                            @change="addPDF($event)"
                        ></v-file-input>
                    </v-col>

                    <v-col cols="12" sm="8" md="8"  >
                        <v-row v-for="(al, a) in announcement.pdfDatas" :key="a">
                        
                                    
                                <v-dialog v-model="al.dialogAction" scrollable max-width="1000px" >
                                    <template v-slot:activator="{ on, attrs }">
                                       
                                        <v-col cols="12" sm="2" md="2"  >
                                            <v-checkbox class="expandcheckbox" v-model="al.expandable" :label="`Expand`" 
                                            ></v-checkbox>
                                        </v-col>
                                         <v-col cols="12" sm="8" md="8"  >
                                            <v-btn small color="primary" id="search-btn" class="ma-2 white--text" 
                                                    
                                                v-bind="attrs" v-on="on"
                                                    @click="al.dialogAction = true">
                                                        <span style="white-space: normal;">
                                                        {{ al.name }}
                                                        </span>
                                                        <v-icon right dark >info</v-icon>
                                            </v-btn>
                                        </v-col>
                                         <v-col cols="12" sm="2" md="2"  >
                                             <v-btn small color="red" class="ma-2 white--text" 
                                                @click="deletePDF(a)">
                                                    <span style="white-space: normal;">
                                                    Delete
                                                    </span>
                                                    <v-icon right dark >delete_forever</v-icon>
                                            </v-btn>
                                        </v-col>
                                        
                                        
                                        
                                    </template>
                                    <v-card>
                                        <!-- <v-card-title><v-icon>check_circle</v-icon> {{this.actionType}}</v-card-title> -->
                                        <v-toolbar color="indigo" dark dense>
                                            <v-toolbar-title>{{ al.name }}</v-toolbar-title>
                                        </v-toolbar>
                                        <v-divider></v-divider>
                                        <v-card-text style="height: 800px;">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="12" >
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="$refs['myPdfComponent' + a][0].print()">
                                                            print
                                                            <v-icon right dark >print</v-icon>
                                                        </v-btn>
                                                        <v-btn small color="primary" id="search-btn" class="printbtn ma-2 white--text"
                                                            @click="downloadPDF(al.data,al.name)">
                                                            Download
                                                            <v-icon right dark >file_download</v-icon>
                                                        </v-btn>
                                                        <pdf 
                                                            :ref="`myPdfComponent${a}`"
                                                            v-for="i in al.numPages"
                                                            :key="i"
                                                            :page="i" 
                                                            :src="al.data">
                                                        </pdf>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="al.dialogAction = false"
                                        >
                                            Close
                                        </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                        
                        </v-row>
                    </v-col>
                </v-row>

                  <v-row class="row-card">
                      <v-col cols="12" sm="12" >
                          <!-- Two-way Data-Binding -->
                            <quill-editor
                                ref="myQuillEditor"
                                v-model="announcement.content"
                                :options="editorOption"
                                @blur="onEditorBlur($event)"
                                @focus="onEditorFocus($event)"
                                @ready="onEditorReady($event)"
                            />
                      </v-col>
                  </v-row>
                  <v-row class="row-card">
                      <v-col cols="12" sm="12" >
                          <!-- Or manually control the data synchronization -->
                        <!-- <quill-editor
                            :content="announcement.content"
                            :options="editorOption"
                            @change="onEditorChange($event)"
                        /> -->
                      </v-col>
                  </v-row>
            </v-card>
          </v-container>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import 'vuejs-noty/dist/vuejs-noty.css'
import Noty from 'noty';
import { validationMixin } from 'vuelidate';
import { required, requiredUnless } from 'vuelidate/lib/validators';
import pdf from 'vue-pdf';

export default {
  mixins: [validationMixin],
  validations: {
    announcement:{
        title: { required },
        category: { required },
        type: { 
            required : requiredUnless(function() {
                         return this.announcement.category == 'General'
                     })
        },
    }
  },
  components: {
    pdf
  },
  name: "Add-Announcement",
  data() {
    return {
        fileupload: null,
        categories: ['General', 'Course', 'Transcript', 'Finance'],
        types: ['General','Intake','MQA','OTHER'],
        announcement: {
            content: '<h2>This is an example...</h2>',
            category: null,
            intakeId: [],
            pdfDatas: [],
            active: true,
            type: null
        },
        intakesList: [],
        editorOption: {
            // Some Quill options...
        }
    };
  },
  created(){
        this.getIntakes();
  },
  methods: {
        downloadPDF(e,filename){
            
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            //var blob = new Blob([e], { type: "application/pdf" }),
            var blob = this.dataURItoBlob(e);
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        dataURItoBlob(dataURI){
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            var ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            var blob = new Blob([ab], {type: mimeString});
            return blob;
        },
        deletePDF(index){
            this.announcement.pdfDatas.splice(index,1);
        },
        addPDF(e){
            var vm = this;
            if(e !== null){
                const selectedImage = e;
                const readerBinary = new FileReader();
                const readerDataUrl = new FileReader();
                var numpage= 1;
                readerBinary.onload= (f=> {
                    numpage = f.target.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                    readerDataUrl.readAsDataURL(selectedImage);
                    
                });
                readerDataUrl.onload= (f=> {
                    let pdfdata = { 
                            name: e.name, 
                            data: f.target.result,
                            dialogAction: false,
                            numPages: numpage,
                            expandable: false
                    };
                    vm.announcement.pdfDatas.push(pdfdata);
                    vm.fileupload = null;
                });
                readerBinary.readAsBinaryString(selectedImage);
            }
        },
        getIntakes(){
            this.listLoading = true
            let loader = this.$loading.show({
                    // Optional parameters
                    });
            
            var vm = this;
            const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

            this.$http.get(process.env.VUE_APP_MICROSERVICE_URL+'/intake/get/all/active',config)
                .then(response => {
                    
                    vm.intakesList = response.data.intakes;

                    vm.listLoading = false;
                    loader.hide();
                    
                })
                .catch(function (error) {
                    vm.listLoading = false
                    console.error(error);
                    loader.hide();
                });
        },
        onEditorBlur(quill) {
            console.log('editor blur!', quill)
        },
        onEditorFocus(quill) {
            console.log('editor focus!', quill)
        },
        onEditorReady(quill) {
            console.log('editor ready!', quill)
        },
        onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text)
            this.announcement.content = html
        },
        addNewAnnouncement(){

            if(this.$v.$invalid  ){
                this.$v.$touch();
            }
            else{
                var vm = this;

                if(vm.announcement.type !== 'Intake'){
                    vm.announcement.intakeId = [];
                }

                this.listLoading = true
                let loader = this.$loading.show({
                    // Optional parameters
                    //container: this.$refs.formContainer,
                    //isFullPage: false
                    });

                
                const config = {
                    headers: { Authorization: `Bearer `+localStorage.getItem('jwt') }
                };

                this.$http.post(process.env.VUE_APP_MICROSERVICE_URL+'/announcement/post?userName='+localStorage.getItem('username'),this.announcement,config)
                .then(response => {

                    
                    loader.hide();

                    //notification of successfully saved
                    var notification = new Noty({
                        killer: false,
                        timeout: 5000,
                        type: 'success',
                        text: 'Announcement ' +vm.announcement.title+ ' is created.',
                        theme: 'metroui',
                        layout: 'topRight'
                    })
                    notification.show();
                    vm.$router.push({
                        path: "/announcement-list"
                    });
                })
                .catch(function (error) {
                    loader.hide();
                    vm.listLoading = false;
                    console.error(error);
                });
            }
        }
   },
    computed: {
        editor() {
            return this.$refs.myQuillEditor.quill
        }
    },
    mounted() {
        console.log('this is current quill instance object', this.editor)
    }
};
</script>

<style scoped>
.v-text-field{
      width: 400px;
}
.createPost-main-container {
    padding: 40px 45px 20px 50px;
}
.container{
    margin-top: 20px;
}

.card-col{
    margin-left:10px;
}

@media (min-width: 1264px){
    .container-card{
        max-width: 1210px;
    }
}

.row-card{
    margin-left: 10px;
    margin-right: 10px;
}

.row-card2{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.main-title{
    line-height: 3;
}
.panel-content{
    margin-top:10px;
}

.checkbox-card-main{
    padding-bottom:0px;
}

.footer{
    padding-bottom:100px;
}

.datemenu > .v-text-field{
    width:unset;
}

.accessmode{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
}
.intervalTitle{
    margin-left: 22px;
    font-weight: unset;
}
.pair-card{
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
}
.row-card-pass{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}
.imagePreview{
    max-width: 500px;
    height: auto;
}
.pageicon{
  margin-right: 20px;
}
.intakeselect /deep/ .v-text-field{
      width: 100%;
}
.printbtn{
    margin-bottom: 20px !important;
}
.expandcheckbox{
    margin-top: 7px;
}
@media (min-width: 1264px){
    /deep/ iframe{
        width: 500px;
        height: 300px;
    }
}
</style>
